import { serverResponseParser } from '../../../utils/request/parser'
import {
  deleteAuthenticatedRequest,
  getAuthenticatedRequest,
  postAuthenticatedRequest,
} from '../request'
import {
  ClientBookings,
  DeleteClientAvailability,
  DeleteMerchantAvailability,
  IPostClientAvailability,
  IPostMerchantAvailability,
  MerchantAvailabilities,
  MerchantBookings,
  PostClientAvailability,
  PostMerchantAvailability,
  clientBookingsParser,
  deleteClientAvailabilityParser,
  deleteMerchantAvailabilityParser,
  merchantAvailabilitiesParser,
  merchantBookingsParser,
  postClientAvailabilityParser,
  postMerchantAvailabilityParser,
} from './types'

export const getMerchantAvailabilitiesByJobId = async (
  jobId: number
): Promise<MerchantAvailabilities> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/availabilities/merchant/${jobId}`,
    (statusCode: number, data: unknown) => {
      return merchantAvailabilitiesParser.check(data)
    },
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const getClientBookingsByJobId = async (
  jobId: number
): Promise<ClientBookings> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/availabilities/bookings/jobs/${jobId}/client`,
    (statusCode: number, serverResponse: unknown) =>
      serverResponseParser(serverResponse, clientBookingsParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const getMerchantBookingsByJobId = async (
  jobId: number
): Promise<MerchantBookings> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/availabilities/bookings/jobs/${jobId}/merchant`,
    (statusCode: number, serverResponse: unknown) =>
      serverResponseParser(serverResponse, merchantBookingsParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const deleteClientAvailabilityByAvailabilityId = async (
  availabilityId: number
): Promise<DeleteClientAvailability> =>
  deleteAuthenticatedRequest(
    `ops/v2/coordination/availabilities/clients/${availabilityId}`,
    (statusCode: number, serverResponse: unknown) =>
      serverResponseParser(serverResponse, deleteClientAvailabilityParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const deleteMerchantAvailabilityByAvailabilityId = async (
  availabilityId: number
): Promise<DeleteMerchantAvailability> =>
  deleteAuthenticatedRequest(
    `ops/v2/coordination/availabilities/merchants/${availabilityId}`,
    (statusCode: number, serverResponse: unknown) =>
      serverResponseParser(serverResponse, deleteMerchantAvailabilityParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const postClientAvailabilityByJobId = async (
  clientId: number,
  startDate: Date,
  endDate: Date,
  jobId: number
): Promise<PostClientAvailability> => {
  const postData: IPostClientAvailability = {
    client_id: clientId,
    start_date: startDate,
    end_date: endDate,
  }

  return postAuthenticatedRequest(
    `ops/v2/coordination/availabilities/clients/${jobId}`,
    postData,
    (statusCode, serverResponse) =>
      serverResponseParser(serverResponse, postClientAvailabilityParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}

export const postMerchantAvailabilityByJobId = async (
  merchantId: number,
  startDate: Date,
  endDate: Date,
  jobId: number
): Promise<PostMerchantAvailability> => {
  const postData: IPostMerchantAvailability = {
    merchant_id: merchantId,
    start_date: startDate,
    end_date: endDate,
  }

  return postAuthenticatedRequest(
    `ops/v2/coordination/availabilities/merchants/${jobId}`,
    postData,
    (statusCode: number, serverResponse: unknown) =>
      serverResponseParser(serverResponse, postMerchantAvailabilityParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}

export default {
  getClientBookingsByJobId,
  getMerchantAvailabilitiesByJobId,
  getMerchantBookingsByJobId,
  deleteClientAvailabilityByAvailabilityId,
  deleteMerchantAvailabilityByAvailabilityId,
  postClientAvailabilityByJobId,
  postMerchantAvailabilityByJobId,
}
