import { noop } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Availability } from '../../../../lib/api/availabilities/types'
import { Job } from '../../../../lib/api/jobs/types'
import {
  getClientAndMerchantAvailabilitiesUnionByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
} from '../../../../lib/redux/availabilities/selectors'
import jobActions from '../../../../lib/redux/jobs/actions'
import { getJobByJobIdSelector } from '../../../../lib/redux/jobs/selectors'
import { formatDateIntervalString } from '../../../../utils/format-strings'
import ScheduleJobStep1Card from '../../../composites/cards/ScheduleJobStep1Card'

export interface IScheduleJobStep1Card {
  className?: string
  jobId: number
  onNextStep?: () => void
}

export default ({
  className = '',
  jobId,
  onNextStep = noop,
}: IScheduleJobStep1Card) => {
  const dispatch = useDispatch()
  const getJobByJobId = useSelector(getJobByJobIdSelector)
  const getMatchingAvailabilitiesByJobId = useSelector(
    getMatchingAvailabilitiesByJobIdSelector
  )
  const matchingAvailabilitiesByJobId = getMatchingAvailabilitiesByJobId(
    jobId
  ).clients.map(availability => ({
    startDate: availability.startDate,
    endDate: availability.endDate,
  }))
  const matchingAvailabilities = matchingAvailabilitiesByJobId.length
    ? matchingAvailabilitiesByJobId
    : useSelector(getClientAndMerchantAvailabilitiesUnionByJobIdSelector(jobId))
  const job = getJobByJobId(jobId)
  const [selectedIndices, setSelectedIndices] = useState((): number[] =>
    matchingAvailabilities.reduce(
      (
        foundIndices: number[],
        availability: Availability,
        index: number
      ): number[] =>
        job &&
        job.startDate &&
        moment(availability.startDate).isSame(moment(job.startDate), 'day')
          ? foundIndices.concat(index)
          : foundIndices,
      []
    )
  )

  const [selectedJob, setSelectedJob] = useState((): Job | null => null)

  const availabilityOptions = matchingAvailabilities.map(availability =>
    // FIXME need a better way
    formatDateIntervalString(
      new Date(availability.startDate),
      new Date(availability.endDate)
    )
  )

  const onSelectOption = (optionIndex: number) => {
    const availability = matchingAvailabilities[optionIndex]
    const scheduleJob = getJobByJobId(jobId)

    if (!scheduleJob) {
      return
    }

    setSelectedJob({
      ...scheduleJob,
      // FIXME need a better way
      startDate: new Date(availability.startDate).toISOString(),
      endDate: new Date(availability.endDate).toISOString(),
    })

    if (selectedIndices.includes(optionIndex)) {
      setSelectedIndices(selectedIndices.filter(index => index !== optionIndex))
    } else {
      setSelectedIndices(selectedIndices.concat(optionIndex))
    }
  }

  const _OnNextStep = () => {
    if (!selectedJob) {
      return
    }

    dispatch(jobActions.scheduleJob(selectedJob))
    onNextStep()
  }

  return (
    <ScheduleJobStep1Card
      className={className}
      availabilities={availabilityOptions}
      onNextStep={_OnNextStep}
      onSelectOption={onSelectOption}
      selectedIndices={selectedIndices}
    />
  )
}
