import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export interface IButtonProps {
  className?: string
  children?: React.ReactNode
  isSelected?: boolean
  onClick?: () => void
  type?: 'button' | 'submit'
}

export const Button = styled.button<IButtonProps>`
    box-sizing: border-box;
    border: 1px solid #344E63;
    border-radius: 4px;
    background-color: ${props =>
      props.isSelected ? '#344E63' : 'transparent'};
    box-shadow: none;
    color: ${props => (props.isSelected ? '#FFFFFF' : '#3F5870')};
    cursor: pointer;
    line-height: 100%;
    outline: none;
    padding: 0;

    &:hover,
    &:active{
        background-color: #344E63;
        color: #FFF;
    }
}
`

export default ({
  className = '',
  children = null,
  isSelected = false,
  onClick = noop,
  type = 'button',
}: IButtonProps) => (
  <Button
    type={type}
    className={className}
    isSelected={isSelected}
    onClick={onClick}
  >
    {children}
  </Button>
)
