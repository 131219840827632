import { noop } from 'lodash'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import CalendarTable, {
  TableColumn as _TableColumn,
  TableRow,
} from '../../../components/tables/Table'
import DateButton, { IDateButton } from './DateButton'
import TodaysDateLabel from './TodaysDateLabel'

const getISODayOfWeek = (date: Date): number =>
  parseInt(moment(date).format('E'), 10)

const getDaysInMonth = (date: Date): number => moment(date).daysInMonth()

const TableColumn = styled(_TableColumn)`
  align-items: center;
  color: #344e63;
  display: flex;
  justify-content: center;
  margin: 2px;
  position: relative;
  width: 15px;
`

const UnavailableDate = styled.div`
  align-items: center;
  color: #e1e4e7;
  display: flex;
  justify-content: center;
  height: 40px;
  text-decoration: line-through;
  width: 40px;
`

const TodayDateButton = styled(DateButton)<IDateButton>`
  background-color: #fa5860;
`

export interface ICalendarTable {
  className?: string
  todayDate?: Date
  currentDate?: Date
  selectedDate?: Date
  startDate?: Date
  endDate?: Date
  onSelectDate?: (date: Date) => void
}

export default ({
  className = '',
  todayDate,
  currentDate = new Date(),
  selectedDate = new Date(),
  startDate,
  endDate,
  onSelectDate = noop,
}: ICalendarTable) => {
  const daysColumn = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'].map((day, index) => (
    <TableColumn key={`table-day-column-${index}`}>{day}</TableColumn>
  ))

  const currentMoment = moment(currentDate)
  const currentStartDay = currentMoment.clone().startOf('month')
  const calendarStartsOnDayOfWeek =
    getISODayOfWeek(currentStartDay.toDate()) % 7 // Sunday is start of the week in the calendar
  const daysInMonth = getDaysInMonth(currentDate)
  const previousMonthLastDay = currentMoment
    .clone()
    .subtract(1, 'months')
    .endOf('month')

  const nextMonthStartDay = currentMoment
    .clone()
    .add(1, 'months')
    .startOf('month')

  const headerChildren = <TableRow>{daysColumn}</TableRow>

  const tableChildren = new Array(7 - (7 - calendarStartsOnDayOfWeek))
    .fill('')
    .map((value, index) =>
      previousMonthLastDay
        .clone()
        .subtract(calendarStartsOnDayOfWeek - index, 'day')
        .toDate()
    )
    .concat(
      new Array(daysInMonth).fill('').map((value, index) =>
        currentStartDay
          .clone()
          .add(index, 'day')
          .toDate()
      )
    )
    .concat(
      new Array(6 * 7 - calendarStartsOnDayOfWeek - daysInMonth)
        .fill('')
        .map((value, index) =>
          nextMonthStartDay
            .clone()
            .add(index, 'day')
            .toDate()
        )
    )
    .reduce((table: React.ReactElement[][], date: Date, index: number) => {
      const dateMoment = moment(date)
      const day = dateMoment.date()
      const row = Math.floor(index / 7)
      const column = index % 7
      if (!table[row]) {
        table[row] = []
      }

      const isDateAvailable = dateMoment.isBetween(
        moment(startDate),
        moment(endDate)
      )
      const isInOtherMonth =
        dateMoment.isBefore(currentStartDay, 'day') ||
        dateMoment.isSameOrAfter(nextMonthStartDay, 'day')

      const isToday = moment(dateMoment).isSame(moment(todayDate), 'day')
      const isDaySelected = !!(
        selectedDate && moment(selectedDate).isSame(dateMoment, 'day')
      )
      const dayContent = !isDateAvailable ? (
        <UnavailableDate>{day}</UnavailableDate>
      ) : isDaySelected ? (
        <DateButton
          isSelected={true}
          isSecondary={isInOtherMonth}
          onClick={() => onSelectDate(date)}
        >
          {day}
        </DateButton>
      ) : isToday ? (
        <TodayDateButton
          isSelected={isToday}
          onClick={() => onSelectDate(date)}
        >
          {day}
        </TodayDateButton>
      ) : (
        <DateButton
          isSecondary={isInOtherMonth}
          onClick={() => onSelectDate(date)}
        >
          {day}
        </DateButton>
      )

      table[row][column] = (
        <TableColumn key={`table-column-${index}`}>
          <TodaysDateLabel isSelected={isToday}>Today</TodaysDateLabel>
          {dayContent}
        </TableColumn>
      )

      return table
    }, [])
    .map((columns, index) => (
      <TableRow key={`table-row-${index}`}>{columns}</TableRow>
    ))

  return (
    <CalendarTable className={className} headerChildren={headerChildren}>
      {tableChildren}
    </CalendarTable>
  )
}
