import queryString from 'query-string'
import { getAuthenticatedRequest, patchAuthenticatedRequest } from '../request'
import {
  GetJobByJobId,
  GetJobsByFilter,
  GetJobsFilter,
  Job,
  PatchJobByJobId,
  getJobByJobIdParser,
  getJobsByFilterParser,
  patchJobByJobIdParser,
} from './types'

import { serverResponseParser } from 'utils/request/parser'

import { JOB_FILTER_END_DATE, JOB_FILTER_START_DATE } from '../../../constants'

const dummyFilter = {
  start_date: JOB_FILTER_START_DATE,
  end_date: JOB_FILTER_END_DATE,
}

export const getJobsByFilter = async (
  filter: GetJobsFilter = dummyFilter
): Promise<GetJobsByFilter> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/jobs?${queryString.stringify(filter, {
      arrayFormat: 'bracket',
    })}`,
    (statusCode, serverResponse) =>
      serverResponseParser(serverResponse, getJobsByFilterParser),
    (statusCode, message) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const patchJobByJobId = async (
  job: Job,
  jobId: number
): Promise<PatchJobByJobId> => {
  const jobData = {
    start_date: job.startDate,
    end_date: job.endDate,
    is_urgent: job.isUrgent,
  }

  return patchAuthenticatedRequest(
    `ops/v2/coordination/jobs/${jobId}`,
    jobData,
    (statusCode: number, data: unknown) =>
      serverResponseParser(data, patchJobByJobIdParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}

export const getJobByJobId = async (jobId: number): Promise<GetJobByJobId> => {
  return getAuthenticatedRequest(
    `ops/v2/coordination/jobs/${jobId}`,
    (statusCode, data) => serverResponseParser(data, getJobByJobIdParser),
    (statusCode, message) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}

export default {
  getJobsByFilter,
  patchJobByJobId,
  getJobByJobId,
}
