import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import SearchView from '../../views/SearchView'

export default () => (
  <Router>
    <Switch>
      <Route exact={true} path="/search">
        <SearchView />
      </Route>
      <Route path="*">
        <Redirect to="/search" />
      </Route>
    </Switch>
  </Router>
)
