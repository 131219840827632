import React from 'react'
import styled from 'styled-components'
import Card from '../../../components/cards/Card'
import Table, { TableRow as _TableRow } from '../../../components/tables/Table'
import _SearchCard, {
  SearchCardProps as FilterSettingsCardProps,
} from '../SearchCard'

export const FilterSettingsCard = styled(Card)`
  margin-top: 20px;
  padding: 20px;

  h1 {
    color: #344e63;
    display: flex;
    font-size: 38px;
    font-weight: normal;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
`

export const TableRow = styled(_TableRow)`
  justify-content: center;
`

export const SearchCard = styled(_SearchCard)`
  width: 500px;
`

export default ({
  className = '',
  loadOptions,
  onChange,
  renderFormatOptionLabel,
}: FilterSettingsCardProps) => (
  <FilterSettingsCard className={className}>
    <Table headerChildren={<h1>Coordination</h1>}>
      <TableRow>
        <SearchCard
          renderFormatOptionLabel={renderFormatOptionLabel}
          loadOptions={loadOptions}
          onChange={onChange}
        />
      </TableRow>
    </Table>
  </FilterSettingsCard>
)
