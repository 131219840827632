import { RemoteData, cata } from 'remote-data-ts'
import { Job } from '../../api/jobs/types'
import { ACTION_TYPES, Action } from './actions'

export type JobId = Job['id']

export type ReduxState = Map<JobId, Job>

export type RemoteDataState = RemoteData<ReduxState, Error>

export const initialReduxState: Map<JobId, Job> = new Map()

export const initialRemoteDataState: RemoteDataState = RemoteData.notAsked()

export const reduxReducer = (
  state: ReduxState = initialReduxState,
  action: Action
): ReduxState => {
  if (action.type === ACTION_TYPES.UPDATE_JOB) {
    state.forEach(job => {
      if (job.id === action.data.job.id) {
        state.set(job.id, action.data.job)
      }
    })
    return new Map(state)
  }

  if (action.type === ACTION_TYPES.UPDATE_JOBS) {
    return new Map(action.data.jobs.map(job => [job.id, job]))
  }

  return state
}

export const remoteDataReducer = (
  state: RemoteDataState = initialRemoteDataState,
  action: Action
): RemoteDataState => {
  return cata<ReduxState, Error, RemoteDataState>({
    notAsked: () => {
      if (action.type === ACTION_TYPES.GET_JOBS_BY_FILTER_INIT) {
        return RemoteData.loading()
      }

      if (
        action.type === ACTION_TYPES.UPDATE_JOB ||
        action.type === ACTION_TYPES.UPDATE_JOBS
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }

      return state
    },
    loading: () => {
      if (
        action.type === ACTION_TYPES.GET_JOBS_BY_FILTER_FAILURE ||
        action.type === ACTION_TYPES.SCHEDULE_JOB_FAILURE
      ) {
        return RemoteData.failure(action.data.error)
      }
      if (
        action.type === ACTION_TYPES.UPDATE_JOB ||
        action.type === ACTION_TYPES.UPDATE_JOBS
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }
      return state
    },
    success: (reduxState: Map<JobId, Job>) => {
      if (action.type === ACTION_TYPES.GET_JOBS_BY_FILTER_INIT) {
        return RemoteData.loading()
      }

      return RemoteData.success(reduxReducer(reduxState, action))
    },
    failure: (error: Error) => {
      if (action.type === ACTION_TYPES.GET_JOBS_BY_FILTER_INIT) {
        return RemoteData.loading()
      }

      return state
    },
  })(state)
}

export default remoteDataReducer
