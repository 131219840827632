import { DateTime, Duration } from 'luxon'

export const ADD_USER_AVAILABILITY_DIALOGUE_TITLE = 'Add user availability'
export const SCHEDULE_JOB_DIALOGUE_TITLE = 'Schedule job'
export const SELECT_JOB_BOOKING_LABEL = 'Select a booking for this job:'
export const SEND_USERS_NOTIFICATIONS =
  'Send Client and Pro a booking reminder:'
export const USER_AVAILABILITIES_NOT_SUBMITTED_LABEL =
  'No availabilities provided'

export const JOB_NOT_SCHEDULED_LABEL = 'Job is not scheduled yet'

export const NO_BOOKINGS_FOR_THIS_DATE_LABEL =
  'There are no bookings available for this date.'

export const SEARCH_DROPDOWN_PLACEHOLDER =
  'Enter Job ID; Client name; Pro name, or ZenDesk ticket'

export const SSO_APP_ID =
  process.env.REACT_APP_SSO_APP_ID || 'staging-coordination'
export const SSO_PROVIDER = process.env.REACT_APP_SSO_PROVIDER || 'google'
export const SSO_REDIRECT_URI =
  process.env.REACT_APP_SSO_REDIRECT_URI ||
  'https://staging.coordination.setter.com'

export enum MATCH_STATUS_TYPE {
  MATCH = 'MATCH',
  MISS_MATCH = 'MISS_MATCH',
  NO_MATCH = 'NO_MATCH',
}

export enum MATCH_STATUS_MESSAGE {
  MATCH = 'Match between Client and Pro availabilities',
  MISS_MATCH = 'Resolve mismatched availabilities',
  NO_MATCH = 'Neither Client and Pro availability provided',
}

export enum SCHEDULE_JOB_INSTRUCTIONS {
  MATCH = 'Schedule a job',
  CLIENT_NO_MATCH = 'Get Client availabilities',
  MERCHANT_NO_MATCH = 'Get Merchant availabilities',
  MISS_MATCH = 'Resolve mismatched availabilities',
  NO_MATCH = 'Get both Client and Pro availabilities',
}

export enum SCHEDULE_JOB_STEPS {
  STEP_1 = 'select a booking',
  STEP_2 = 'send notifications to users',
  STEP_3 = 'notifications sent',
}

export enum USER_TYPES {
  CLIENT = 'CLIENT',
  MERCHANT = 'MERCHANT',
}

const ONE_YEAR = Duration.fromObject({ years: 1 })
export const CURRENT_DATE = DateTime.local()
export const JOB_FILTER_START_DATE = CURRENT_DATE.minus(ONE_YEAR).toISODate()
export const JOB_FILTER_END_DATE = CURRENT_DATE.plus(ONE_YEAR).toISODate()
