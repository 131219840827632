import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  JOB_NOT_SCHEDULED_LABEL,
  MATCH_STATUS_MESSAGE,
  MATCH_STATUS_TYPE,
  SCHEDULE_JOB_INSTRUCTIONS,
} from '../../../../constants'
import _PrimaryButton from '../../../components/buttons/PrimaryButton'
import ScheduleJobCard from '../../../components/cards/Card'
import MatchStatusIcon from '../../icons/MatchStatusIcon'

export interface IScheduleJobCard {
  className?: string
  jobMatchStatusType?: MATCH_STATUS_TYPE | null
  jobScheduledDate?: string
  jobMatchStatus?: MATCH_STATUS_MESSAGE
  nextSteps?: SCHEDULE_JOB_INSTRUCTIONS
  canJobBeScheduled: boolean
  onScheduleJob?: () => void
}

export const Row = styled.div`
  margin-bottom: 35px;
  span {
    align-items: center;
    display: flex;
  }

  label {
    margin-left: 10px;
  }
`

export const PrimaryButton = styled(_PrimaryButton)`
  margin-top: 20px;
`

export default ({
  className = '',
  jobMatchStatusType = null,
  jobMatchStatus = MATCH_STATUS_MESSAGE.NO_MATCH,
  canJobBeScheduled = false,
  jobScheduledDate = JOB_NOT_SCHEDULED_LABEL,
  nextSteps = SCHEDULE_JOB_INSTRUCTIONS.NO_MATCH,
  onScheduleJob = noop,
}: IScheduleJobCard) => {
  const scheduleJobButton = canJobBeScheduled && (
    <PrimaryButton isSelected={true} onClick={() => onScheduleJob()}>
      Schedule Job
    </PrimaryButton>
  )

  return (
    <ScheduleJobCard className={className}>
      <Row>
        <h2>Status:</h2>
        <span>
          <MatchStatusIcon type={jobMatchStatusType} />
          <label>{jobMatchStatus}</label>
        </span>
      </Row>

      <Row>
        <h2>Next Steps:</h2>
        {nextSteps}
      </Row>

      <Row>
        <h2>Job Scheduled Date:</h2>
        {jobScheduledDate}
        <br />
        {scheduleJobButton}
      </Row>
    </ScheduleJobCard>
  )
}
