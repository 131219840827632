import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export interface ICard {
  className?: string
  children?: React.ReactNode[] | React.ReactNode | null
  onClick?: () => void
}

export const Card = styled.div`
  color: #3f5870;
  font-size: 16px;
  padding: 25px;

  h1 {
    align-items: center;
    display: flex;
    color: #344e63;
    font-size: 24px;
    font-weight: normal;
  }

  h2 {
    align-items: center;
    color: #344e63;
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }

  h3 {
    align-items: center;
    font-size: 14px;
    display: flex;
    color: #344e63;
    font-weight: normal;
  }

  h4 {
    align-items: center;
    font-size: 12px;
    display: flex;
    color: #344e63;
    font-weight: normal;
  }
`

export default ({ className = '', children = null, onClick = noop }: ICard) => (
  <Card className={className} onClick={onClick}>
    {children}
  </Card>
)
