import { Dispatch } from 'lib/redux'
import { ReduxState } from 'lib/redux/session'
import { validateSSOSession } from 'lib/redux/session/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cata } from 'remote-data-ts'
import { getSessionSelector } from '../../../../lib/redux/session/selectors'
import AuthenticatedApp from '../AuthenticatedApp'
import UnauthenticatedApp from '../UnauthenticatedApp'

export default () => {
  const sessionState = useSelector(getSessionSelector)
  const dispatch = useDispatch<Dispatch>()

  return cata({
    notAsked: () => {
      dispatch(validateSSOSession())

      return <div>not asked</div>
    },
    loading: () => <div>loading ...</div>,
    success: (session: ReduxState) => {
      if (session.type === 'authenticated') {
        return <AuthenticatedApp />
      }

      return <UnauthenticatedApp />
    },
    failure: () => <div>woops!</div>,
  })(sessionState)
}
