import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import logger from 'redux-logger'
import thunk, { ThunkDispatch } from 'redux-thunk'

import jobs, { RemoteDataState as JobsRemoteDataState } from './jobs'
import { Action as JobsAction } from './jobs/actions'

import availabilities, {
  RemoteDataState as AvailabilitiesRemoteDataState,
} from './availabilities'
import { Action as AvailabilitiesAction } from './availabilities/actions'
import { Action as NotificationAction } from './notifications/actions'
import profiles, {
  RemoteDataState as ProfilesRemoteDataState,
} from './profiles'
import { Action as ProfilesAction } from './profiles/actions'
import session, { RemoteDataState as SessionRemoteDataState } from './session'
import { Action as SessionAction } from './session/actions'

import search, { RemoteDataState as SearchRemoteDataState } from './search'
import { Action as SearchAction } from './search/actions'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
  }
}

export interface IState {
  availabilities: AvailabilitiesRemoteDataState
  jobs: JobsRemoteDataState
  profiles: ProfilesRemoteDataState
  session: SessionRemoteDataState
  search: SearchRemoteDataState
}

export type Action =
  | AvailabilitiesAction
  | JobsAction
  | NotificationAction
  | ProfilesAction
  | SessionAction
  | SearchAction

export type GetState = () => IState
export type Dispatch = ThunkDispatch<IState, {}, Action>

export const reducers = combineReducers({
  availabilities,
  jobs,
  profiles,
  session,
  search,
})

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, logger))
)

export default store
