import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import FlagIcon from '../../icons/FlagIcon'

export interface IFlagToggleButton {
  className?: string
  isSelected?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const FlagToggleButton = styled.button<IFlagToggleButton>`
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  outline: none;
`

export default ({
  className = '',
  isSelected = false,
  onClick = noop,
}: IFlagToggleButton) => (
  <FlagToggleButton
    data-testid="flag-toggle-button"
    className={className}
    isSelected={isSelected}
    onClick={onClick}
  >
    <FlagIcon filled={isSelected} />
  </FlagToggleButton>
)
