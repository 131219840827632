import jwt from 'jsonwebtoken'
import { Dispatch } from '..'
import { identify } from '../../analytics'
import api from '../../api/session'
import { ApplicationUserProfile, SessionUser } from '../../api/session/types'
import { getCachedSSOSessionToken, getCachedSessionToken } from './index'

export enum ACTION_TYPES {
  VALIDATE_SESSION_INIT = 'VALIDATE_SESSION_INIT',
  VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS',
  VALIDATE_SESSION_FAILURE = 'VALIDATE_SESSION_FAILURE',
  UNAUTHENTICATED_SESSION_INIT = 'UNAUTHENTICATED_SESSION_INIT',
  LOGIN_USER_INIT = 'LOGIN_INIT',
  LOGIN_USER_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_USER_FAILURE = 'LOGIN_FAILURE',
  LOGOUT_USER_INIT = 'LOGOUT_USER_INIT',
  LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE',
}

type SessionValidation =
  | { user: SessionUser; token: string }
  | { user: undefined; token: undefined }

export type Action =
  | { type: ACTION_TYPES.VALIDATE_SESSION_INIT }
  | {
      type: ACTION_TYPES.VALIDATE_SESSION_SUCCESS
      data: SessionValidation
    }
  | { type: ACTION_TYPES.VALIDATE_SESSION_FAILURE }
  | { type: ACTION_TYPES.UNAUTHENTICATED_SESSION_INIT }
  | { type: ACTION_TYPES.LOGIN_USER_INIT }
  | {
      type: ACTION_TYPES.LOGIN_USER_SUCCESS
      data: { type: 'authenticated'; token: string; user: SessionUser }
    }
  | { type: ACTION_TYPES.LOGIN_USER_FAILURE; data: { error: Error } }
  | { type: ACTION_TYPES.LOGOUT_USER_INIT }
  | { type: ACTION_TYPES.LOGOUT_USER_SUCCESS }
  | { type: ACTION_TYPES.LOGOUT_USER_FAILURE; data: { error: Error } }

export const unauthenticateSessionInit = (): Action => ({
  type: ACTION_TYPES.UNAUTHENTICATED_SESSION_INIT,
})

const validateSessionInit = (): Action => ({
  type: ACTION_TYPES.VALIDATE_SESSION_INIT,
})

const validateSessionSuccess = (result: SessionValidation): Action => ({
  type: ACTION_TYPES.VALIDATE_SESSION_SUCCESS,
  data: result,
})

const validateSessionFailure = (): Action => ({
  type: ACTION_TYPES.VALIDATE_SESSION_FAILURE,
})

export const validateSession = () => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(validateSessionInit())

    const sessionToken = getCachedSessionToken()

    if (!sessionToken) {
      dispatch(unauthenticateSessionInit())
      return
    }

    const user = await api.verifySession(sessionToken)
    if (user) {
      dispatch(validateSessionSuccess({ user, token: sessionToken }))
    } else {
      dispatch(validateSessionSuccess({ user, token: undefined }))
    }
  } catch (e) {
    dispatch(validateSessionFailure())
  }
}

export const validateSSOSession = () => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(validateSessionInit())
    const ssoToken = getCachedSSOSessionToken()
    if (!ssoToken) {
      dispatch(unauthenticateSessionInit())
      return
    }

    const key = await api.getPublicKey()
    if (!key) {
      throw new Error('Key not found ')
    }

    const profile = jwt.verify(ssoToken, key.pub) as ApplicationUserProfile
    const { given_name, family_name, email, cellphone, exp } = profile
    if (Date.now() > 1000 * exp) {
      throw new Error('jwt Expired')
    }

    const user = {
      first_name: given_name,
      last_name: family_name,
      email: email,
      cellphone: cellphone,
    }
    if (user) {
      dispatch(validateSessionSuccess({ user, token: ssoToken }))
    } else {
      dispatch(validateSessionSuccess({ user, token: undefined }))
    }
  } catch (e) {
    dispatch(logoutUser())
  }
}

export const loginUser = (email: string, password: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_TYPES.LOGIN_USER_INIT })
      const { user, session_id: token } = await api.loginUser(email, password)
      identify(user)
      dispatch({
        type: ACTION_TYPES.LOGIN_USER_SUCCESS,
        data: { type: 'authenticated', token, user },
      })
    } catch (error) {
      dispatch({ type: ACTION_TYPES.LOGIN_USER_FAILURE, data: { error } })
    }
  }
}

export const loginUserWithSSO = () => {
  return (dispatch: Dispatch): void => {
    try {
      dispatch({ type: ACTION_TYPES.LOGIN_USER_INIT })
      api.loginWithSSO()
    } catch (error) {
      dispatch({ type: ACTION_TYPES.LOGIN_USER_FAILURE, data: { error } })
    }
  }
}

export const logoutUser = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_TYPES.LOGOUT_USER_INIT })
      dispatch({ type: ACTION_TYPES.LOGOUT_USER_SUCCESS })
    } catch (error) {
      dispatch({ type: ACTION_TYPES.LOGOUT_USER_SUCCESS, data: { error } })
    }
  }
}

export default {
  loginUser,
  logoutUser,
  validateSession,
}
