import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import _PrimaryButton from '../../../components/buttons/PrimaryButton'
import ScheduleJobStep3Card from '../../../components/cards/Card'
import _CheckBoxCircleIcon from '../../../components/icons/CheckBoxCircleIcon'

const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const PrimaryButton = styled(_PrimaryButton)`
  margin-top: 30px;
`
const CheckBoxCircleIcon = styled(_CheckBoxCircleIcon)`
  margin-bottom: 30px;
`

export interface IScheduleJobStep3Card {
  className?: string
  invitationDate?: string
  onNextStep?: () => void
}

export default ({
  className = '',
  invitationDate = 'N/A',
  onNextStep = noop,
}: IScheduleJobStep3Card) => {
  return (
    <ScheduleJobStep3Card className={className}>
      <Column>
        <CheckBoxCircleIcon />
        <h2>{invitationDate}</h2>
        The Client and the Pro have been notified for this upcoming job!
        <PrimaryButton isSelected={true} onClick={() => onNextStep()}>
          Okay
        </PrimaryButton>
      </Column>
    </ScheduleJobStep3Card>
  )
}
