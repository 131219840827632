import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { ReactComponent as CheckBoxComponent } from './assets/check-box.svg'

export const CheckBoxIcon = styled(Icon)`
  align-items: center;
  background-color: #ff4856;
  display: flex;
  justify-content: center;
  opacity: 0.65;
`

export interface ICloseCircle {
  className?: string
}

export default ({ className }: ICloseCircle) => (
  <CheckBoxIcon className={className}>
    <CheckBoxComponent />
  </CheckBoxIcon>
)
