import { get } from 'lodash'
import { createSelector } from 'reselect'

import {
  formatPhoneNumber,
  formatUserFullName,
} from '../../../utils/format-strings'
import {
  getMatchStatusByJobIdSelector,
  getMatchStatusTypeByJobIdSelector,
} from '../availabilities/selectors'
import { getJobsSelector } from '../jobs/selectors'
import {
  getClientProfileByClientIdSelector,
  getMerchantProfileByMerchantIdSelector,
} from '../profiles/selectors'
import { ResultsState } from './index'

export const getResultsSelector = createSelector(
  getJobsSelector,
  getClientProfileByClientIdSelector,
  getMerchantProfileByMerchantIdSelector,
  getMatchStatusTypeByJobIdSelector,
  getMatchStatusByJobIdSelector,
  (
    jobState,
    getClientProfileByClientId,
    getMerchantProfileByMerchantId,
    getMatchStatusTypeByJobId,
    getMatchStatusByJobId
  ): ResultsState[] => {
    // TODO implement filtering and sorting
    return [...jobState.values()].map(job => {
      const clientProfile = getClientProfileByClientId(job.clientId)
      const merchantProfile = getMerchantProfileByMerchantId(job.merchantId)
      const jobMatchStatusType = getMatchStatusTypeByJobId(job.id)
      const jobMatchStatus = getMatchStatusByJobId(job.id)
      return {
        jobId: job.id,
        jobTitle: job.title,
        jobType: job.type,
        ticketCreatedDate: undefined,
        jobMatchStatusType: jobMatchStatusType,
        jobMatchStatus: jobMatchStatus,
        jobStartDate: job.startDate ? new Date(job.startDate) : undefined,
        jobEndDate: job.endDate ? new Date(job.endDate) : undefined,
        jobApprovedDate: new Date(job.approvedDate),
        clientId: get(clientProfile, 'clientId', -1),
        clientName: formatUserFullName(
          get(clientProfile, 'lastName', 'N/A'),
          get(clientProfile, 'firstName', 'N/A')
        ),
        clientPhoneNumber: get(clientProfile, 'cellphone', 'N/A'),
        merchantId: get(merchantProfile, 'merchantId', -1),
        merchantName: formatUserFullName(get(merchantProfile, 'name', 'N/A')),
        merchantPhoneNumber: formatPhoneNumber(
          get(merchantProfile, 'primaryPhoneNumber', 'N/A')
        ),
        merchantPhoneNumberSecondary: formatPhoneNumber(
          get(merchantProfile, 'secondaryPhoneNumber', 'N/A')
        ),
        isJobUrgent: job.isUrgent,
        isClientVIP: clientProfile ? clientProfile.isVip : false,
      }
    })
  }
)

export const getResultsBySearchStringSelector = getResultsSelector
