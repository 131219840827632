import * as rt from 'runtypes'
import sharedTypes, { jobParser } from '../shared-parsers'

import { clientProfileParser, merchantProfileParser } from '../profiles/types'

export const clientAvailabilityParser = sharedTypes.clientAvailabilityParser

export const clientAvailabilitiesParser = rt.Record({
  jobs: rt.Array(jobParser),
  clientProfiles: rt.Array(clientProfileParser),
  merchantProfiles: rt.Array(merchantProfileParser),
  clientAvailabilities: rt.Array(clientAvailabilityParser),
})

export const clientBookingParser = rt.Record({
  clientId: rt.Number,
  jobId: rt.Number,
  startDate: rt.String,
  endDate: rt.String,
  type: rt.Literal('client-booking'),
})

export const merchantAvailabilityParser = sharedTypes.merchantAvailabilityParser

export const merchantAvailabilitiesParser = rt.Record({
  jobs: rt.Array(jobParser),
  clientProfiles: rt.Array(clientProfileParser),
  merchantProfiles: rt.Array(merchantProfileParser),
  merchantAvailabilities: rt.Array(merchantAvailabilityParser),
})

export const merchantBookingParser = rt.Record({
  merchantId: rt.Number,
  jobId: rt.Number,
  startDate: rt.String,
  endDate: rt.String,
  type: rt.Literal('merchant-booking'),
})

export const clientBookingsParser = rt.Record({
  clientAvailabilities: rt.Array(clientAvailabilityParser),
  clientBookings: rt.Array(clientBookingParser),
  clientProfile: clientProfileParser,
  job: jobParser,
})

export const merchantBookingsParser = rt.Record({
  merchantAvailabilities: rt.Array(merchantAvailabilityParser),
  merchantBookings: rt.Array(merchantBookingParser),
  merchantProfile: merchantProfileParser,
  job: jobParser,
})

export const deleteClientAvailabilityParser = rt.Record({
  job: jobParser,
  clientAvailabilities: rt.Array(clientAvailabilityParser),
  clientProfile: clientProfileParser,
  merchantProfile: merchantProfileParser,
})

export const deleteMerchantAvailabilityParser = rt.Record({
  job: jobParser,
  merchantAvailabilities: rt.Array(merchantAvailabilityParser),
  clientProfile: clientProfileParser,
  merchantProfile: merchantProfileParser,
})

export const postClientAvailabilityParser = rt.Record({
  job: jobParser,
  clientAvailabilities: rt.Array(clientAvailabilityParser),
  clientProfile: clientProfileParser,
  merchantProfile: merchantProfileParser,
  newClientAvailability: clientAvailabilityParser,
})

export const postMerchantAvailabilityParser = rt.Record({
  job: jobParser,
  merchantAvailabilities: rt.Array(merchantAvailabilityParser),
  clientProfile: clientProfileParser,
  merchantProfile: merchantProfileParser,
  newMerchantAvailability: merchantAvailabilityParser,
})

export interface Availability {
  startDate: string
  endDate: string
}

export type ClientAvailability = rt.Static<typeof clientAvailabilityParser>

export type ClientAvailabilities = rt.Static<typeof clientAvailabilitiesParser>

export type MerchantAvailability = rt.Static<typeof merchantAvailabilityParser>

export type MerchantAvailabilities = rt.Static<
  typeof merchantAvailabilitiesParser
>

export type ClientBooking = rt.Static<typeof clientBookingParser>

export type ClientBookings = rt.Static<typeof clientBookingsParser>

export type MerchantBooking = rt.Static<typeof merchantBookingParser>

export type MerchantBookings = rt.Static<typeof merchantBookingsParser>

export type DeleteClientAvailability = rt.Static<
  typeof deleteClientAvailabilityParser
>

export type DeleteMerchantAvailability = rt.Static<
  typeof deleteMerchantAvailabilityParser
>

export type PostClientAvailability = rt.Static<
  typeof postClientAvailabilityParser
>

export type PostMerchantAvailability = rt.Static<
  typeof postMerchantAvailabilityParser
>

export interface IPostClientAvailability {
  client_id: number
  start_date: Date
  end_date: Date
}

export interface IPostMerchantAvailability {
  merchant_id: number
  start_date: Date
  end_date: Date
}

export default {
  clientAvailabilityParser,
  clientAvailabilitiesParser,
  clientBookingParser,
  merchantAvailabilityParser,
  merchantAvailabilitiesParser,
  merchantBookingParser,
  deleteClientAvailabilityParser,
  deleteMerchantAvailabilityParser,
  postClientAvailabilityParser,
}
