import * as rt from 'runtypes'
import { clientProfileParser, merchantProfileParser } from '../profiles/types'
import sharedParsers from '../shared-parsers'

export const jobParser = sharedParsers.jobParser

export const getJobsByFilterParser = rt.Record({
  jobs: rt.Array(jobParser),
  clientAvailabilities: rt.Array(sharedParsers.clientAvailabilityParser),
  merchantAvailabilities: rt.Array(sharedParsers.merchantAvailabilityParser),
  clientProfiles: rt.Array(clientProfileParser),
  merchantProfiles: rt.Array(merchantProfileParser),
})

export const patchJobByJobIdParser = rt.Record({
  job: jobParser,
  clientProfile: clientProfileParser,
  merchantProfile: merchantProfileParser,
})

export const getJobByJobIdParser = patchJobByJobIdParser

export type GetJobsByFilter = rt.Static<typeof getJobsByFilterParser>

export type Job = rt.Static<typeof jobParser>

export type PatchJob = {
  start_date: string | undefined
  end_date: string | undefined
  is_urgent: boolean | undefined
}

export type PatchJobByJobId = rt.Static<typeof patchJobByJobIdParser>

export type GetJobByJobId = rt.Static<typeof getJobByJobIdParser>

export type GetJobsFilter = {
  start_date?: string
  end_date?: string
  time_zone?: string
  client_ids?: number[]
  merchant_ids?: number[]
  order_ids?: number[]
  job_ids?: number[]
}

export type JobsFilter = {
  startDate?: string
  endDate?: string
  timeZone?: string
  clientIds?: number[]
  merchantIds?: number[]
  orderIds?: number[]
  jobIds?: number[]
}

export default {
  getJobsByFilterParser,
  getJobByJobIdParser,
  jobParser,
  patchJobByJobIdParser,
}
