import { noop } from 'lodash'
import React from 'react'
import Modal from '../../../components/modals/Modal'
import ScheduleJobDialogue from '../../../containers/dialogues/ScheduleJobDialogue'

export interface IScheduleJobDialogue {
  className?: string
  isShowing?: boolean
  jobId: number
  onCloseDialogue?: () => void
}

export default ({
  className = '',
  isShowing = false,
  jobId,
  onCloseDialogue = noop,
}: IScheduleJobDialogue) => (
  <Modal isShowing={isShowing}>
    <ScheduleJobDialogue
      className={className}
      jobId={jobId}
      onCloseDialogue={onCloseDialogue}
    />
  </Modal>
)
