import { cata } from 'remote-data-ts'
import { createSelector } from 'reselect'
import { IState } from '../index'
import { ReduxState, RemoteDataState } from './index'

export const getSessionSelector = (state: IState): RemoteDataState =>
  state.session

export const isAuthenticatedSelector = createSelector(
  getSessionSelector,
  (remoteData): boolean =>
    cata({
      notAsked: () => false,
      loading: () => false,
      success: (session: ReduxState) => session.type === 'authenticated',
      failure: () => false,
    })(remoteData)
)

export const getAuthenticationTokenSelector = createSelector(
  getSessionSelector,
  (session): string | undefined =>
    cata({
      notAsked: () => undefined,
      loading: () => undefined,
      success: (user: ReduxState) => {
        if (user.type === 'authenticated') {
          return user.token
        } else {
          return undefined
        }
      },
      failure: () => undefined,
    })(session)
)
export default {
  getSessionSelector,
  isAuthenticatedSelector,
  getAuthenticationTokenSelector,
}
