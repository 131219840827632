import { noop } from 'lodash'
import React from 'react'
import DatePickerTable from '../../../components/tables/Table'
import CalendarTable from '../CalendarTable'
import MonthPickerTable from '../MonthPickerTable'

export interface IDatePickerTable {
  className?: string
  todayDate?: Date
  currentDate?: Date
  selectedDate?: Date
  startDate?: Date
  endDate?: Date
  onSelectDate?: (date: Date) => void
  onSelectNextMonth?: (date: Date) => void
  onSelectPreviousMonth?: (date: Date) => void
}

export default ({
  className = '',
  todayDate,
  currentDate = new Date(),
  selectedDate = new Date(),
  startDate,
  endDate,
  onSelectDate = noop,
  onSelectNextMonth = noop,
  onSelectPreviousMonth = noop,
}: IDatePickerTable) => {
  const tableHeaderChildren = (
    <MonthPickerTable
      currentDate={currentDate}
      startDate={startDate}
      endDate={endDate}
      onSelectNextMonth={onSelectNextMonth}
      onSelectPreviousMonth={onSelectPreviousMonth}
    />
  )
  return (
    <DatePickerTable className={className} headerChildren={tableHeaderChildren}>
      <CalendarTable
        todayDate={todayDate}
        currentDate={currentDate}
        selectedDate={selectedDate}
        startDate={startDate}
        endDate={endDate}
        onSelectDate={onSelectDate}
      />
    </DatePickerTable>
  )
}
