import api, { ISendNotifications } from '../../api/notifications'
import { Dispatch } from '../index'
export enum ACTION_TYPES {
  SEND_CLIENT_NOTIFICATION_FAILURE = 'SEND_CLIENT_NOTIFICATION_FAILURE',
  SEND_CLIENT_NOTIFICATION_INIT = 'SEND_CLIENT_NOTIFICATION_INIT',
  SEND_CLIENT_NOTIFICATION_SUCCESS = 'SEND_CLIENT_NOTIFICATION_SUCCESS',
  SEND_MERCHANT_NOTIFICATION_FAILURE = 'SEND_MERCHANT_NOTIFICATION_FAILURE',
  SEND_MERCHANT_NOTIFICATION_INIT = 'SEND_MERCHANT_NOTIFICATION_INIT',
  SEND_MERCHANT_NOTIFICATION_SUCCESS = 'SEND_MERCHANT_NOTIFICATION_SUCCESS',
}

export type Action =
  | {
      type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_INIT
      data: { jobId: number }
    }
  | { type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_SUCCESS }
  | {
      type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_INIT
      data: { jobId: number }
    }
  | { type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_SUCCESS }

export const sendClientNotifications = (
  sendNotifications: ISendNotifications,
  jobId: number
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_INIT,
        data: { jobId },
      })

      await api.postClientNotificationsByJobId(sendNotifications, jobId)

      dispatch({ type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_SUCCESS })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.SEND_CLIENT_NOTIFICATION_FAILURE,
        data: { error },
      })
    }
  }
}

export const sendMerchantNotifications = (
  sendNotifications: ISendNotifications,
  jobId: number
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_INIT,
        data: { jobId },
      })

      await api.postMerchantNotificationsByJobId(sendNotifications, jobId)

      dispatch({ type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_SUCCESS })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.SEND_MERCHANT_NOTIFICATION_FAILURE,
        data: { error },
      })
    }
  }
}

export default {
  sendClientNotifications,
  sendMerchantNotifications,
}
