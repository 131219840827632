import React from 'react'
import styled from 'styled-components'
import Card from '../../../components/cards/Card'
import SetterLogoIcon from '../../../components/icons/SetterLogoIcon'

export const SetterLogoCard = styled(Card)`
  background-color: #fff;
  padding: 25px 40px;
`

export default () => (
  <SetterLogoCard>
    <SetterLogoIcon />
  </SetterLogoCard>
)
