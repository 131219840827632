import React from 'react'
import Icon from '../Icon'
import { ReactComponent as FilledIconComponent } from './assets/star-filled.svg'
import { ReactComponent as UnfilledIconComponent } from './assets/star-unfilled.svg'

export const StarIcon = Icon

export interface IStarIcon {
  className?: string
  filled?: boolean
}
export default ({ className, filled = false }: IStarIcon) => (
  <StarIcon className={className}>
    {filled ? <FilledIconComponent /> : <UnfilledIconComponent />}
  </StarIcon>
)
