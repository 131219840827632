import React from 'react'
import Icon from '../Icon'
import { ReactComponent as IconComponent } from './assets/plus.svg'

export const PlusIcon = Icon

export interface IPlusIcon {
  className?: string
}
export default ({ className }: IPlusIcon) => (
  <PlusIcon className={className}>
    <IconComponent />
  </PlusIcon>
)
