import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { IButtonProps } from '../Button'

export interface IToggleButton extends IButtonProps {
  children?: React.ReactElement[] | React.ReactElement | null
  isSelected?: boolean
  onClick?: () => void
}

export const ToggleButton = styled.button<IToggleButton>`
  align-items: center;
  background-color: ${props => (props.isSelected ? '#FF4856' : '#FFFFFF')};
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 24px;
`

export default ({
  className = '',
  children = null,
  isSelected = false,
  onClick = noop,
}: IToggleButton) => (
  <ToggleButton className={className} isSelected={isSelected} onClick={onClick}>
    {children}
  </ToggleButton>
)
