import { noop } from 'lodash'
import React from 'react'
import {
  SCHEDULE_JOB_DIALOGUE_TITLE,
  SCHEDULE_JOB_STEPS,
} from '../../../../constants'
import ScheduleJobDialogue from '../../../components/dialogues/Dialogue'
import ScheduleJobStep1Card from '../../../containers/cards/ScheduleJobStep1Card'
import ScheduleJobStep2Card from '../../../containers/cards/ScheduleJobStep2Card'
import ScheduleJobStep3Card from '../../../containers/cards/ScheduleJobStep3Card'

export interface IScheduleJobDialogue {
  className?: string
  jobId: number
  onCloseDialogue?: () => void
  onNextStep?: () => void
  onPreviousStep?: () => void
  scheduleJobStep?: SCHEDULE_JOB_STEPS
}

export default ({
  className = '',
  jobId,
  onCloseDialogue = noop,
  onNextStep = noop,
  onPreviousStep = noop,
  scheduleJobStep = SCHEDULE_JOB_STEPS.STEP_1,
}: IScheduleJobDialogue) => {
  return (
    <ScheduleJobDialogue
      className={className}
      headerChildren={SCHEDULE_JOB_DIALOGUE_TITLE}
      onCloseDialogue={onCloseDialogue}
    >
      {scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_1 ? (
        <ScheduleJobStep1Card jobId={jobId} onNextStep={onNextStep} />
      ) : null}
      {scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_2 ? (
        <ScheduleJobStep2Card
          jobId={jobId}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      ) : null}
      {scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_3 ? (
        <ScheduleJobStep3Card jobId={jobId} onNextStep={onNextStep} />
      ) : null}
    </ScheduleJobDialogue>
  )
}
