import { debounce } from 'lodash'
import React, { RefObject, useRef } from 'react'
import { FormatOptionLabelMeta } from 'react-select'
import { ActionMeta, OptionsType, ValueType } from 'react-select/src/types'
import styled from 'styled-components'
import { AdditionalParams } from 'ui/components/dropdown/Dropdown'
import SearchCard from '../../../components/cards/Card'
import _SearchIcon from '../../../components/icons/SearchIcon'
import _Table, { TableRow as _TableRow } from '../../../components/tables/Table'
import SearchDropdown, {
  SearchDropdownProps,
} from '../../dropdowns/SearchDropdown'

export interface SearchCardProps extends SearchDropdownProps {
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsType<AdditionalParams>) => void
  ) => void
  onChange: (value: ValueType<AdditionalParams>, action: ActionMeta) => void
  renderFormatOptionLabel?: (
    option: AdditionalParams,
    labelMeta: FormatOptionLabelMeta<AdditionalParams>
  ) => React.ReactNode
}

export type loadResultsCallback = (
  options: OptionsType<AdditionalParams>
) => void

export const Table = styled(_Table)`
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
`

export const TableRow = styled(_TableRow)`
  padding: 5px 10px;
  width: auto;
`

export const SearchIcon = styled(_SearchIcon)`
  flex: 0 0 auto;
  margin-right: 10px;
`

export default ({
  className = '',
  loadOptions,
  onChange,
  renderFormatOptionLabel,
}: SearchCardProps) => {
  const textInputRef: RefObject<HTMLInputElement> = useRef(null)
  const onClickCard = () => textInputRef.current && textInputRef.current.focus()

  const debounceOnLoadResults = debounce(loadOptions, 500)

  const debouncedOnLoadOptions = (
    input: string,
    callback: loadResultsCallback
  ) => {
    debounceOnLoadResults(input, callback)
  }

  return (
    <SearchCard className={className} onClick={onClickCard}>
      <Table>
        <TableRow>
          <SearchIcon />
          <SearchDropdown
            innerRef={textInputRef}
            loadOptions={debouncedOnLoadOptions}
            onChange={onChange}
            formatOptionLabel={renderFormatOptionLabel}
          />
        </TableRow>
      </Table>
    </SearchCard>
  )
}
