import { Interval } from 'luxon'
import moment from 'moment'
import { cata } from 'remote-data-ts'
import { createSelector } from 'reselect'
import {
  MATCH_STATUS_MESSAGE,
  MATCH_STATUS_TYPE,
  SCHEDULE_JOB_INSTRUCTIONS,
} from '../../../constants'
import {
  ClientAvailability,
  ClientBooking,
  MerchantAvailability,
  MerchantBooking,
} from '../../api/availabilities/types'
import { ReduxState, initialReduxState } from './index'

import { IState } from '../index'

type MatchingAvailabilitiesByJobId = {
  clients: ClientAvailability[]
  merchants: MerchantAvailability[]
}

const defaultState = (): ReduxState => initialReduxState

export const getAvailabilitiesSelector = (state: IState): ReduxState =>
  cata({
    notAsked: defaultState,
    loading: defaultState,
    success: (reduxState: ReduxState): ReduxState => reduxState,
    failure: defaultState,
  })(state.availabilities)

export const getClientAvailabilitiesSelector = createSelector(
  getAvailabilitiesSelector,
  (availabilities): ClientAvailability[] => availabilities.clients
)

export const getMerchantAvailabilitiesSelector = createSelector(
  getAvailabilitiesSelector,
  (availabilities): MerchantAvailability[] => availabilities.merchants
)

export const getClientAvailabilitiesByClientIdSelector = createSelector(
  getClientAvailabilitiesSelector,
  clientAvailabilities => (
    clientId: number,
    jobId: number
  ): ClientAvailability[] =>
    clientAvailabilities
      .filter(
        clientAvailability =>
          clientAvailability.clientId === clientId &&
          clientAvailability.jobId === jobId
      )
      .sort((a: ClientAvailability, b: ClientAvailability) => {
        const aMoment = moment(a.startDate)
        const bMoment = moment(b.startDate)
        return aMoment.isBefore(bMoment) ? -1 : aMoment.isSame(bMoment) ? 0 : 1
      })
)

export const getMerchantAvailabilitiesByMerchantIdSelector = createSelector(
  getMerchantAvailabilitiesSelector,
  merchantAvailabilities => (
    merchantId: number,
    jobId: number
  ): MerchantAvailability[] =>
    merchantAvailabilities
      .filter(
        merchantAvailability =>
          merchantAvailability.merchantId === merchantId &&
          merchantAvailability.jobId === jobId
      )
      .sort((a: MerchantAvailability, b: MerchantAvailability) => {
        const aMoment = moment(a.startDate)
        const bMoment = moment(b.startDate)
        return aMoment.isBefore(bMoment) ? -1 : aMoment.isSame(bMoment) ? 0 : 1
      })
)

export const getClientBookingsSelector = createSelector(
  getAvailabilitiesSelector,
  (availabilities): ClientBooking[] => availabilities.clientBookings
)

export const getClientBookingsByJobIdSelector = createSelector(
  getClientBookingsSelector,
  clientBookings => (jobId: number): ClientBooking[] =>
    clientBookings.filter(booking => booking.jobId === jobId)
)

export const getMerchantBookingsSelector = createSelector(
  getAvailabilitiesSelector,
  (availabilities): MerchantBooking[] => availabilities.merchantBookings
)

export const getMerchantBookingsByJobIdSelector = createSelector(
  getMerchantBookingsSelector,
  merchantBookings => (jobId: number): MerchantBooking[] =>
    merchantBookings.filter(booking => booking.jobId === jobId)
)

export const getClientAvailabilitiesByJobIdSelector = createSelector(
  getClientAvailabilitiesSelector,
  clientAvailabilities => (jobId: number) =>
    clientAvailabilities.filter(availability => availability.jobId === jobId)
)

export const getMerchantAvailabilitiesByJobIdSelector = createSelector(
  getMerchantAvailabilitiesSelector,
  merchantAvailabilities => (jobId: number) =>
    merchantAvailabilities.filter(availability => availability.jobId === jobId)
)

export const getClientAndMerchantAvailabilitiesUnionByJobIdSelector = (
  jobId: number
) =>
  createSelector(
    getClientAvailabilitiesByJobIdSelector,
    getMerchantAvailabilitiesByJobIdSelector,
    (getClientAvailabilitiesByJobId, getMerchantAvailabilitiesByJobId) => {
      const availabilities = [
        ...getClientAvailabilitiesByJobId(jobId),
        ...getMerchantAvailabilitiesByJobId(jobId),
      ]
      return availabilities.map(({ startDate, endDate }) => ({
        startDate,
        endDate,
      }))
    }
  )

export const getMatchingAvailabilitiesByJobIdSelector = createSelector(
  getClientAvailabilitiesByJobIdSelector,
  getMerchantAvailabilitiesByJobIdSelector,
  (getClientAvailabilitiesByJobId, getMerchantAvailabilitiesByJobId) => (
    jobId: number
  ): MatchingAvailabilitiesByJobId => {
    const clientAvailabilities = getClientAvailabilitiesByJobId(jobId)
    const merchantAvailabilities = getMerchantAvailabilitiesByJobId(jobId)

    return clientAvailabilities.reduce(
      (
        userMatches: MatchingAvailabilitiesByJobId,
        clientAvailability: ClientAvailability
      ) =>
        merchantAvailabilities.reduce(
          (
            _userMatches: MatchingAvailabilitiesByJobId,
            merchantAvailability: MerchantAvailability
          ) => {
            const clientInterval = Interval.fromDateTimes(
              // FIXME - need a better way
              new Date(clientAvailability.startDate),
              new Date(clientAvailability.endDate)
            )
            const merchantInterval = Interval.fromDateTimes(
              // FIXME - need a better way
              new Date(merchantAvailability.startDate),
              new Date(merchantAvailability.endDate)
            )

            if (clientInterval.equals(merchantInterval)) {
              return {
                clients: _userMatches.clients.concat(clientAvailability),
                merchants: _userMatches.merchants.concat(merchantAvailability),
              }
            }

            return _userMatches
          },
          userMatches
        ),
      initialReduxState
    )
  }
)

export const getMatchStatusTypeByJobIdSelector = createSelector(
  getClientAvailabilitiesByJobIdSelector,
  getMerchantAvailabilitiesByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  (
    getClientAvailabilitiesByJobId,
    getMerchantAvailabilitiesByJobId,
    getMatchingAvailabilitiesByJobId
  ) => (jobId: number) => {
    const clientAvailabilities = getClientAvailabilitiesByJobId(jobId)
    const merchantAvailabilities = getMerchantAvailabilitiesByJobId(jobId)
    const matches = getMatchingAvailabilitiesByJobId(jobId)

    if (matches && matches.clients.length) {
      return MATCH_STATUS_TYPE.MATCH
    }

    if (
      (clientAvailabilities.length > 0 &&
        merchantAvailabilities.length === 0) ||
      (clientAvailabilities.length === 0 &&
        merchantAvailabilities.length > 0) ||
      clientAvailabilities.length > 0 ||
      merchantAvailabilities.length > 0
    ) {
      return MATCH_STATUS_TYPE.MISS_MATCH
    }

    return MATCH_STATUS_TYPE.NO_MATCH
  }
)

export const canJobBeScheduledByJobIdSelector = (jobId: number) =>
  createSelector(
    getClientAvailabilitiesByJobIdSelector,
    getMerchantAvailabilitiesByJobIdSelector,
    (getClientAvailabilitiesByJobId, getMerchantAvailabilitiesByJobId) => {
      const clientAvailabilities = getClientAvailabilitiesByJobId(jobId)
      const merchantAvailabilities = getMerchantAvailabilitiesByJobId(jobId)

      return (
        clientAvailabilities.length > 0 || merchantAvailabilities.length > 0
      )
    }
  )

export const getClientMatchStatusTypeByJobIdSelector = createSelector(
  getClientAvailabilitiesByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  (getClientAvailabilitiesByJobId, getMatchingAvailabilitiesByJobId) => (
    jobId: number
  ) => {
    const availabilities = getClientAvailabilitiesByJobId(jobId)
    const matches = getMatchingAvailabilitiesByJobId(jobId).clients
    if (matches.length) {
      return MATCH_STATUS_TYPE.MATCH
    }

    if (availabilities.length > 0) {
      return MATCH_STATUS_TYPE.MISS_MATCH
    }

    return MATCH_STATUS_TYPE.NO_MATCH
  }
)

export const getMerchantMatchStatusTypeByJobIdSelector = createSelector(
  getMerchantAvailabilitiesByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  (getMerchantAvailabilitiesByJobId, getMatchingAvailabilitiesByJobId) => (
    jobId: number
  ) => {
    const availabilities = getMerchantAvailabilitiesByJobId(jobId)
    const matches = getMatchingAvailabilitiesByJobId(jobId).merchants
    if (matches.length) {
      return MATCH_STATUS_TYPE.MATCH
    }

    if (availabilities.length > 0) {
      return MATCH_STATUS_TYPE.MISS_MATCH
    }

    return MATCH_STATUS_TYPE.NO_MATCH
  }
)

export const getMatchStatusByJobIdSelector = createSelector(
  getMatchStatusTypeByJobIdSelector,
  getMatchStatusTypeByJobId => (jobId: number) => {
    const matchType = getMatchStatusTypeByJobId(jobId)

    if (matchType === MATCH_STATUS_TYPE.MATCH) {
      return MATCH_STATUS_MESSAGE.MATCH
    }

    if (matchType === MATCH_STATUS_TYPE.MISS_MATCH) {
      return MATCH_STATUS_MESSAGE.MISS_MATCH
    }

    return MATCH_STATUS_MESSAGE.NO_MATCH
  }
)

export const getScheduleJobInstructionsByJobIdSelector = createSelector(
  getClientAvailabilitiesByJobIdSelector,
  getMerchantAvailabilitiesByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  (
    getClientAvailabilitiesByJobId,
    getMerchantAvailabilitiesByJobId,
    getMatchingAvailabilitiesByJobId
  ) => (jobId: number) => {
    const clientAvailabilities = getClientAvailabilitiesByJobId(jobId)
    const merchantAvailabilities = getMerchantAvailabilitiesByJobId(jobId)
    const matches = getMatchingAvailabilitiesByJobId(jobId)

    if (matches && matches.clients.length) {
      return SCHEDULE_JOB_INSTRUCTIONS.MATCH
    }

    if (
      clientAvailabilities.length > 0 &&
      merchantAvailabilities.length === 0
    ) {
      return SCHEDULE_JOB_INSTRUCTIONS.MERCHANT_NO_MATCH
    }

    if (
      clientAvailabilities.length === 0 &&
      merchantAvailabilities.length > 0
    ) {
      return SCHEDULE_JOB_INSTRUCTIONS.CLIENT_NO_MATCH
    }

    if (clientAvailabilities.length > 0 || merchantAvailabilities.length > 0) {
      return SCHEDULE_JOB_INSTRUCTIONS.MISS_MATCH
    }

    return SCHEDULE_JOB_INSTRUCTIONS.NO_MATCH
  }
)

export default {
  getAvailabilitiesSelector,
  getClientAvailabilitiesSelector,
  getClientAvailabilitiesByClientIdSelector,
  getClientBookingsSelector,
  getClientBookingsByJobIdSelector,
  getClientMatchStatusTypeByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  getMatchStatusByJobIdSelector,
  getMatchStatusTypeByJobIdSelector,
  getMerchantAvailabilitiesSelector,
  getMerchantAvailabilitiesByJobIdSelector,
  getMerchantMatchStatusTypeByJobIdSelector,
  getMerchantBookingsSelector,
  getMerchantBookingsByJobIdSelector,
  getScheduleJobInstructionsByJobIdSelector,
}
