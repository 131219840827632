import React from 'react'
import { useSelector } from 'react-redux'
import { getJobByJobIdSelector } from '../../../../lib/redux/jobs/selectors'
import {
  getClientProfileByClientIdSelector,
  getMerchantProfileByMerchantIdSelector,
} from '../../../../lib/redux/profiles/selectors'
import ClientAndMerchantAvailabilityCard from '../../../composites/cards/ClientAndMerchantAvailabilityCard'
import UserContactsCards from '../../../composites/cards/UserContactsCard'

export interface IClientAndMerchantAvailabilityCard {
  className?: string
  jobId: number
}

export default ({
  className = '',
  jobId,
}: IClientAndMerchantAvailabilityCard) => {
  const getJobByJobId = useSelector(getJobByJobIdSelector)
  const getClientProfileByClientId = useSelector(
    getClientProfileByClientIdSelector
  )
  const getMerchantProfileByMerchantId = useSelector(
    getMerchantProfileByMerchantIdSelector
  )
  const job = getJobByJobId(jobId)
  if (!job) {
    return <div>job not found</div>
  }

  const clientProfile = getClientProfileByClientId(job.clientId)
  const merchantProfile = getMerchantProfileByMerchantId(job.merchantId)

  const clientDetailsChildren = clientProfile && (
    <UserContactsCards phoneNumber={clientProfile.cellphone} />
  )

  const merchantDetailsChildren = merchantProfile && (
    <UserContactsCards
      phoneNumber={merchantProfile.primaryPhoneNumber}
      phoneNumberSecondary={merchantProfile.secondaryPhoneNumber}
    />
  )

  return (
    <ClientAndMerchantAvailabilityCard
      className={className}
      clientDetailsChildren={clientDetailsChildren}
      clientId={job.clientId}
      jobId={jobId}
      merchantDetailsChildren={merchantDetailsChildren}
      merchantId={job.merchantId}
    />
  )
}
