import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

export interface IModal {
  children?: React.ReactElement[] | React.ReactElement | null
  isShowing?: boolean
}

export const Modal = styled.div`
	align-items: center
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000
`
export default ({ children = null, isShowing = false }: IModal) => {
  if (isShowing) {
    document.body.style.overflow = 'hidden'
    const modalContent = <Modal>{children}</Modal>
    return ReactDOM.createPortal(modalContent, document.body)
  } else {
    document.body.style.overflow = 'auto'
    return null
  }
}
