import * as rt from 'runtypes'

export const sessionUserParser = rt.Record({
  first_name: rt.String,
  last_name: rt.String,
  email: rt.String,
  cellphone: rt.String.Or(rt.Undefined),
})

export const applicationUserProfileParser = rt.Record({
  sub: rt.String,
  name: rt.String,
  given_name: rt.String,
  family_name: rt.String,
  picture: rt.String,
  email: rt.String,
  email_verified: rt.String,
  locale: rt.String,
  hd: rt.String,
  cellphone: rt.String,
  exp: rt.Number,
})

export const publicKeyParser = rt.Record({
  kty: rt.String,
  alg: rt.String,
  pub: rt.String,
  aud: rt.String,
  iss: rt.String,
})

export const sessionParser = rt.Record({
  session_id: rt.String,
  user: sessionUserParser,
})

export type Session = rt.Static<typeof sessionParser>
export type SessionUser = rt.Static<typeof sessionUserParser>
export type PublicKey = rt.Static<typeof publicKeyParser>
export type ApplicationUserProfile = rt.Static<
  typeof applicationUserProfileParser
>
