import { noop, size } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import _ToggleLabelledButton from '../../../components/buttons/ToggleLabelledButton'
import { TableColumn as _TableColumn } from '../../../components/tables/Table'

export interface ISelectableList {
  className?: string
  emptyListMessage?: string
  options?: string[]
  onSelectOption?: (optionIndex: number) => void
  selectedIndices?: number[]
}

export const ToggleLabelledButton = styled(_ToggleLabelledButton)`
  padding: 10px;
  width: 100%;
`

export const SelectableList = styled(_TableColumn)`
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li {
      align-items: center;
      display: flex;
      margin-bottom: 10px

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export const EmptyList = styled.div`
  color: #3f5870;
  font-size: 12px;
  text-align: center;
`

export default ({
  className = '',
  emptyListMessage = 'N/A',
  options = [],
  onSelectOption = noop,
  selectedIndices = [],
}: ISelectableList) => {
  if (size(options) > 0) {
    const list = options.map((option: string, index: number) => (
      <li key={`selectable-list${index}`}>
        <ToggleLabelledButton
          onSelectOption={() => onSelectOption(index)}
          isSelected={selectedIndices.includes(index)}
        >
          {option}
        </ToggleLabelledButton>
      </li>
    ))
    return (
      <SelectableList className={className}>
        <ul>{list}</ul>
      </SelectableList>
    )
  }

  return <EmptyList className={className}>{emptyListMessage}</EmptyList>
}
