import React from 'react'
import styled from 'styled-components'

export const Icon = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  width: 24px;
`

export interface IIcon {
  className?: string
  children?: React.ReactNode | null
}

export default ({ className, children = null }: IIcon) => (
  <Icon className={className}>{children}</Icon>
)
