import { noop } from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  sendClientNotifications,
  sendMerchantNotifications,
} from '../../../../lib/redux/notifications/actions'
import ScheduleJobStep2Card from '../../../composites/cards/ScheduleJobStep2Card'

export interface IScheduleJobStep2Card {
  className?: string
  jobId: number
  onNextStep?: () => void
  onPreviousStep?: () => void
}

export default ({
  className = '',
  jobId,
  onNextStep = noop,
  onPreviousStep = noop,
}: IScheduleJobStep2Card) => {
  const dispatch = useDispatch()
  const [
    selectedClientIndices,
    setSelectedClientIndices,
  ] = useState((): number[] => [])
  const [
    selectedMerchantIndices,
    setSelectedMerchantIndices,
  ] = useState((): number[] => [])
  const onSelectClientNotificationSettings = (index: number) => {
    if (selectedClientIndices.includes(index)) {
      return setSelectedClientIndices(
        selectedClientIndices.filter(clientIndex => clientIndex !== index)
      )
    }

    return setSelectedClientIndices(selectedClientIndices.concat(index))
  }

  const onSelectMerchantNotificationSettings = (index: number) => {
    if (selectedMerchantIndices.includes(index)) {
      setSelectedMerchantIndices(
        selectedMerchantIndices.filter(merchantIndex => merchantIndex !== index)
      )
    }

    return setSelectedMerchantIndices(selectedMerchantIndices.concat(index))
  }

  const onSubmitNotificationSettings = () => {
    const sendClientEmail = selectedClientIndices.includes(0)
    const sendClientText = selectedClientIndices.includes(1)
    const sendMerchantEmail = selectedMerchantIndices.includes(0)
    const sendMerchantText = selectedMerchantIndices.includes(1)

    if (sendClientEmail || sendClientText) {
      dispatch(
        sendClientNotifications(
          { sendEmail: sendClientEmail, sendText: sendClientText },
          jobId
        )
      )
    }

    if (sendMerchantEmail || sendMerchantText) {
      dispatch(
        sendMerchantNotifications(
          { sendEmail: sendMerchantEmail, sendText: sendMerchantText },
          jobId
        )
      )
    }

    onNextStep()
  }

  return (
    <ScheduleJobStep2Card
      className={className}
      onNextStep={onSubmitNotificationSettings}
      onPreviousStep={onPreviousStep}
      onSelectClientNotificationSettings={onSelectClientNotificationSettings}
      onSelectMerchantNotificationSettings={
        onSelectMerchantNotificationSettings
      }
      selectedClientNotificationSettingIndices={selectedClientIndices}
      selectedMerchantNotificationSettingIndices={selectedMerchantIndices}
    />
  )
}
