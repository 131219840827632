import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import RightArrowCircleIcon from '../../icons/RightArrowCircleIcon'

export interface IRightArrowButton {
  className?: string
  onClick?: () => void
}

export const RightArrowButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

export default ({ className = '', onClick = noop }: IRightArrowButton) => (
  <RightArrowButton className={className} onClick={onClick}>
    <RightArrowCircleIcon />
  </RightArrowButton>
)
