import { SearchResult } from 'lib/api/search/types'
import { Dispatch } from '../index'
import { getJobsByFilter } from '../jobs/actions'

export enum ACTION_TYPES {
  UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER',
  UPDATE_SEARCH_FILTER_INIT = 'UPDATE_SEARCH_FILTER_INIT',
  UPDATE_SEARCH_FILTER_FAILURE = 'UPDATE_SEARCH_FILTER_FAILURE',
  UPDATE_SEARCH_FILTER_SUCCESS = 'UPDATE_SEARCH_FILTER_SUCCESS',
  REMOVE_SEARCH_FILTER = 'REMOVE_SEARCH_FILTER',
  REMOVE_SEARCH_FILTER_INIT = 'REMOVE_SEARCH_FILTER_INIT',
  REMOVE_SEARCH_FILTER_FAILURE = 'REMOVE_SEARCH_FILTER_FAILURE',
  REMOVE_SEARCH_FILTER_SUCCESS = 'REMOVE_SEARCH_FILTER_SUCCESS',
  RESET_SEARCH_FILTER = 'RESET_SEARCH_FILTER',
  RESET_SEARCH_FILTER_INIT = 'RESET_SEARCH_FILTER_INIT',
  RESET_SEARCH_FILTER_FAILURE = 'RESET_SEARCH_FILTER_FAILURE',
  RESET_SEARCH_FILTER_SUCCESS = 'RESET_SEARCH_FILTER_SUCCESS',
  UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS',
}

export type Action =
  | {
      type: ACTION_TYPES.UPDATE_SEARCH_FILTER
      data: { result: SearchResult }
    }
  | { type: ACTION_TYPES.UPDATE_SEARCH_FILTER_INIT }
  | { type: ACTION_TYPES.UPDATE_SEARCH_FILTER_SUCCESS }
  | {
      type: ACTION_TYPES.UPDATE_SEARCH_FILTER_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.REMOVE_SEARCH_FILTER
      data: { result: SearchResult }
    }
  | { type: ACTION_TYPES.REMOVE_SEARCH_FILTER_INIT }
  | { type: ACTION_TYPES.REMOVE_SEARCH_FILTER_SUCCESS }
  | {
      type: ACTION_TYPES.REMOVE_SEARCH_FILTER_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.RESET_SEARCH_FILTER }
  | {
      type: ACTION_TYPES.RESET_SEARCH_FILTER_INIT
    }
  | { type: ACTION_TYPES.RESET_SEARCH_FILTER_SUCCESS }
  | {
      type: ACTION_TYPES.RESET_SEARCH_FILTER_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.UPDATE_SEARCH_RESULTS
      data: { results: SearchResult[] }
    }

export const addSearchFilter = (result: SearchResult) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_FILTER_INIT })
    dispatch({
      type: ACTION_TYPES.UPDATE_SEARCH_FILTER,
      data: { result },
    })
    dispatch(getJobsByFilter())
    dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_FILTER_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.UPDATE_SEARCH_FILTER_FAILURE,
      data: { error },
    })
  }
}

export const removeSearchFilter = (result: SearchResult) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.REMOVE_SEARCH_FILTER_INIT })
    dispatch({
      type: ACTION_TYPES.REMOVE_SEARCH_FILTER,
      data: { result },
    })
    dispatch(getJobsByFilter())
    dispatch({ type: ACTION_TYPES.REMOVE_SEARCH_FILTER_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.REMOVE_SEARCH_FILTER_FAILURE,
      data: { error },
    })
  }
}

export const resetSearchFilter = () => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.RESET_SEARCH_FILTER_INIT })
    dispatch({ type: ACTION_TYPES.RESET_SEARCH_FILTER })
    dispatch(getJobsByFilter())
    dispatch({ type: ACTION_TYPES.RESET_SEARCH_FILTER_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.RESET_SEARCH_FILTER_FAILURE,
      data: { error },
    })
  }
}

export default {
  addSearchFilter,
  removeSearchFilter,
  resetSearchFilter,
}
