import React from 'react'
import styled from 'styled-components'
import { formatPhoneNumber } from '../../../../utils/format-strings'

export interface IUserContacts {
  className?: string
  phoneNumber?: string
  phoneNumberSecondary?: string
}

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`

export default ({
  className = '',
  phoneNumber = '',
  phoneNumberSecondary = '',
}: IUserContacts) => (
  <UserDetails className={className}>
    Primary {formatPhoneNumber(phoneNumber)}
    <br />
    Secondary {formatPhoneNumber(phoneNumberSecondary)}
  </UserDetails>
)
