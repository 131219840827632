import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../../buttons/PrimaryButton'
import View from '../View'

export interface IView {
  children?: React.ReactNode | null
  onClickLogout?: () => void
}

export const LogoutButton = styled(PrimaryButton)`
  margin-right: 40px;
`

export default ({ children, onClickLogout }: IView) => (
  <View
    headerChildren={<LogoutButton onClick={onClickLogout}>Logout</LogoutButton>}
    children={children}
  />
)
