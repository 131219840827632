import configs from '../configs'
import { AnalyticsEvent, AnalyticsEventData, AnalyticsUser } from './types'

// This function snippet is taken from Segment https://github.com/segmentio/analytics-react
const initAnalytics = () => {
  const segmentWriteKey = configs.analytics.segmentWriteKey
  const analytics = window.analytics
  if (analytics && !analytics.initialized) {
    analytics.load(segmentWriteKey)
  }
}

const track = (eventName: AnalyticsEvent, eventData: AnalyticsEventData) => {
  const analytics = window.analytics
  if (analytics) {
    analytics.track(eventName, eventData)
  } else {
    console.error('Analytics is not initialized')
  }
}

const identify = (user: AnalyticsUser) => {
  const analytics = window.analytics
  if (analytics) {
    analytics.identify(user.email, {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    })
  } else {
    console.error('Analytics is not initialized')
  }
}

export { initAnalytics, track, identify }
