import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import AddLabelledButton from '../../../components/buttons/AddLabelledButton'
import Card from '../../../components/cards/Card'
import List from '../../lists/List'
import NoAvailabilitiesCard from '../NoAvailabilitiesCard'

export const AddAvailabilityButton = styled(AddLabelledButton)`
  margin-top: 20px;
`

export interface IAvailabilitiesListCard {
  className?: string
  options?: string[]
  onAddAvailability?: () => void
  onDeleteAvailability?: (index: number) => void
  selectedIndices?: number[]
}

export const AvailabilitiesListCard = styled(Card)`
  padding: 0;
`

export const Container = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  height: 115px;
`

export default ({
  className = '',
  options = [],
  onAddAvailability = noop,
  onDeleteAvailability = noop,
  selectedIndices = [],
}: IAvailabilitiesListCard) => {
  const content = options.length ? (
    <List
      onDeleteOption={onDeleteAvailability}
      options={options}
      selectedIndices={selectedIndices}
    />
  ) : (
    <NoAvailabilitiesCard />
  )

  return (
    <AvailabilitiesListCard className={className}>
      <Container>{content}</Container>
      <AddAvailabilityButton onClick={() => onAddAvailability()}>
        Add Availability
      </AddAvailabilityButton>
    </AvailabilitiesListCard>
  )
}
