import { SSO_APP_ID, SSO_PROVIDER, SSO_REDIRECT_URI } from '../../../constants'
import { SSO_BASE_URL, getRequest, postRequest } from '../request'
import {
  PublicKey,
  Session,
  SessionUser,
  publicKeyParser,
  sessionParser,
  sessionUserParser,
} from './types'

export const loginUser = async (
  email: string,
  password: string
): Promise<Session> =>
  postRequest(
    'ops/v1/login',
    {
      email,
      password,
    },
    (statusCode: number, data: unknown) => {
      return sessionParser.check(data)
    },
    (statusCode: number, data: string) => {
      throw new Error(data)
    }
  )

export const verifySession = async (
  token: string
): Promise<SessionUser | undefined> =>
  getRequest(
    'ops/v1/verify_session',
    (status, data) => sessionUserParser.check(data),
    (status, data) => {
      // token has gone stale / expired
      if (status === 401) {
        return undefined
      } else {
        throw new Error(data)
      }
    },
    { Authorization: token }
  )

export const loginWithSSO = async () => {
  const ssoURL = `${SSO_BASE_URL}/auth/${SSO_APP_ID}/${SSO_PROVIDER}?redirect=${encodeURIComponent(
    SSO_REDIRECT_URI
  )}`
  window.location.href = ssoURL
}

export const getPublicKey = async (): Promise<PublicKey> => {
  const url = `${SSO_BASE_URL}/jwt/public`
  return getRequest(
    '',
    (status, data) => publicKeyParser.check(data),
    (status, data) => {
      throw new Error(data)
    },
    {},
    url
  )
}

export default {
  loginUser,
  loginWithSSO,
  verifySession,
  getPublicKey,
}
