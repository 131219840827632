import { noop } from 'lodash'
import { PROFILE_TYPES } from '../profiles/types'
import { postAuthenticatedRequest } from '../request'

export interface ISendNotifications {
  sendEmail?: boolean | undefined
  sendText?: boolean | undefined
}

export const postNotificationsByJobId = async (
  sendNotifications: ISendNotifications,
  profileType: PROFILE_TYPES,
  jobId: number
) =>
  postAuthenticatedRequest(
    `ops/v2/coordination/notifications/jobs/${jobId}/${profileType}`,
    {
      send_email: sendNotifications.sendEmail,
      send_text: sendNotifications.sendText,
    },
    (statusCode: number, data: unknown) => noop,
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const postClientNotificationsByJobId = async (
  sendNotifications: ISendNotifications,
  jobId: number
) => postNotificationsByJobId(sendNotifications, PROFILE_TYPES.CLIENT, jobId)

export const postMerchantNotificationsByJobId = async (
  sendNotifications: ISendNotifications,
  jobId: number
) => postNotificationsByJobId(sendNotifications, PROFILE_TYPES.MERCHANT, jobId)

export default {
  postClientNotificationsByJobId,
  postMerchantNotificationsByJobId,
}
