import * as rt from 'runtypes'

const errorServerResponseParser = rt.Record({
  success: rt.Literal(false),
  data: rt.String,
})

const successfullServerResponseParser = rt.Record({
  success: rt.Literal(true),
  data: rt.Unknown,
})

const _serverResponseParser = rt.Union(
  errorServerResponseParser,
  successfullServerResponseParser
)

export const serverResponseParser = <T>(
  serverResponse: unknown,
  dataParser: rt.Runtype<T>
): T => {
  const result = _serverResponseParser.check(serverResponse)

  if (result.success) {
    return dataParser.check(result.data)
  } else {
    throw new Error(`Server Error: ${result.data}`)
  }
}

type NestedData<T> = {
  [key: string]: T
}

export const nestedDataParser = <T>(
  key: string,
  dataParser: rt.Runtype<T>
): rt.Runtype<NestedData<T>> =>
  rt.Record({
    [key]: dataParser,
  })
