import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Dispatch } from '../../../../lib/redux'
import jobsActions from '../../../../lib/redux/jobs/actions'
import profilesActions from '../../../../lib/redux/profiles/actions'
import { getResultsSelector } from '../../../../lib/redux/results/selectors'
import _Card from '../../../components/cards/Card'
import ResultsTable from '../../../composites/tables/ResultsTable'
import ClientAndMerchantAvailabilityCard from '../../../containers/cards/ClientAndMerchantAvailabilityCard'

export interface IResultsCard {
  className?: string
}

export const ResultsCard = styled(_Card)`
  padding: 20px;
`

export const Title = styled.div`
  margin-left: 20px;
`

export default ({ className = '' }: IResultsCard) => {
  const dispatch = useDispatch<Dispatch>()
  const results = useSelector(getResultsSelector)

  useEffect(() => {
    dispatch(jobsActions.getJobsByFilter())
  }, [dispatch])

  const onToggleJobUrgencyStatus = (jobId: number) =>
    dispatch(jobsActions.toggleJobUrgencyStatus(jobId))

  const onToggleClientVIPStatus = (clientId: number) =>
    dispatch(profilesActions.toggleClientVIPStatus(clientId))

  return (
    <ResultsCard className={className}>
      <Title>
        <h2>Search results:</h2>
      </Title>
      <ResultsTable
        results={results}
        onToggleJobUrgencyStatus={onToggleJobUrgencyStatus}
        onToggleClientVIPStatus={onToggleClientVIPStatus}
        renderDetailRow={result => (
          <ClientAndMerchantAvailabilityCard jobId={result.jobId} />
        )}
      />
    </ResultsCard>
  )
}
