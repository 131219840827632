import * as api from 'lib/api/search'
import { SearchResult } from 'lib/api/search/types'
import {
  addSearchFilter,
  removeSearchFilter,
  resetSearchFilter,
} from 'lib/redux/search/actions'
import React from 'react'
import { useDispatch } from 'react-redux'
import { FormatOptionLabelMeta } from 'react-select'
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select/src/types'
import { AdditionalParams } from 'ui/components/dropdown/Dropdown'
import { loadResultsCallback } from 'ui/composites/cards/SearchCard'
import { SearchOptionCard } from 'ui/composites/dropdowns/SearchDropdown'
import FilterSettingsCard from '../../../composites/cards/FilterSettingsCard'
import ResultsCard from '../../cards/ResultsCard'
import SearchView from '../AuthenticatedView'

export interface SearchResultActionMeta extends ActionMeta {
  option?: SearchResult
  removedValue?: SearchResult
}

export default () => {
  const dispatch = useDispatch()

  const getSearchResults = async (
    inputValue: string,
    callback: loadResultsCallback
  ) => {
    api
      .postSearchResults(inputValue)
      .catch(() => [])
      .then((results: SearchResult[]) =>
        results.map((result: SearchResult) => ({
          ...result,
          title: result.header,
          label: result.header,
          value: result.id_string,
        }))
      )
      .then(callback)
  }

  const onFilterChange = (
    value: ValueType<AdditionalParams>,
    actionMeta: SearchResultActionMeta
  ) => {
    const { action, removedValue, option } = actionMeta
    if (action === 'clear') {
      dispatch(resetSearchFilter())
    }
    if ((action === 'remove-value' || action === 'pop-value') && removedValue) {
      dispatch(removeSearchFilter(removedValue))
    }
    if (action === 'select-option' && option) {
      dispatch(addSearchFilter(option))
    }
  }

  const renderFormatOptionLabel = (
    { title, resource, description }: OptionTypeBase,
    lableMeta: FormatOptionLabelMeta<OptionTypeBase>
  ) =>
    lableMeta.context === 'menu' ? (
      <SearchOptionCard>
        <div className="menu">
          <h2>{title}</h2>
          <h3>{resource}</h3>
          <h4>{description}</h4>
        </div>
      </SearchOptionCard>
    ) : (
      <SearchOptionCard>
        <h2>{title}</h2>
        <h3>{resource}</h3>
      </SearchOptionCard>
    )

  return (
    <SearchView>
      <FilterSettingsCard
        loadOptions={getSearchResults}
        onChange={onFilterChange}
        renderFormatOptionLabel={renderFormatOptionLabel}
      />
      <ResultsCard />
    </SearchView>
  )
}
