import { noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import {
  ADD_USER_AVAILABILITY_DIALOGUE_TITLE,
  NO_BOOKINGS_FOR_THIS_DATE_LABEL,
} from '../../../../constants'
import {
  ClientAvailability,
  ClientBooking,
  MerchantAvailability,
  MerchantBooking,
} from '../../../../lib/api/availabilities/types'
import {
  formatDateIntervalString,
  formatTo12HourTime,
} from '../../../../utils/format-strings'
import AddAvailabilityDialogue from '../../../components/dialogues/Dialogue'
import _DatePickerTable from '../../../containers/tables/DatePickerTable'
import NoAvailabilitiesCard from '../../cards/NoAvailabilitiesCard'
import _List from '../../lists/List'
import SelectableList from '../../lists/SelectableList'
import Footer from './Footer'

const MAXIMUM_AVAILABILITIES = 3

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`

const DatePickerTable = styled(_DatePickerTable)`
  background-color: #ffffff;
  border-radius: 4px;
  flex: 0 0 auto;
  padding: 15px;
  height: 330px;
  width: 335px;
`

const SelectableAvailabilitiesList = styled(SelectableList)`
  background-color: #fff;
  border-radius: 4px;
  flex: 0 0 auto;
  height: 330px;
  overflow: auto;
  margin-left: 15px;
  padding: 15px;
  width: 140px;
`

const Wrapper = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  height: 115px;
  width: 360px;
`

const SelectedAvailabilitiesList = styled(_List)`
  display: flex;
  justify-content: center;
`

export interface IAddAvailabilityDialogue {
  className?: string
  bookings?: Array<ClientBooking | MerchantBooking>
  todayDate?: Date
  currentDate?: Date
  startDate?: Date
  endDate?: Date
  dialogueTitle?: string
  onCloseDialogue?: () => void
  onConfirmDialogue?: () => void
  onAddAvailability?: (startDate: Date, endDate: Date) => void
  onDeleteAvailability?: (optionIndex: number) => void
  onSelectDate?: (selectedDate: Date) => void
  selectedAvailabilities?: Array<ClientAvailability | MerchantAvailability>
}

export default ({
  className = '',
  bookings = [],
  todayDate,
  currentDate = new Date(),
  startDate,
  endDate,
  dialogueTitle = ADD_USER_AVAILABILITY_DIALOGUE_TITLE,
  onCloseDialogue = noop,
  onConfirmDialogue = noop,
  onAddAvailability = noop,
  onDeleteAvailability = noop,
  onSelectDate = noop,
  selectedAvailabilities = [],
}: IAddAvailabilityDialogue) => {
  const headerChildren = dialogueTitle
  const footerChildren = <Footer onConfirm={onConfirmDialogue} />

  const bookingOptions = bookings.map((booking): string => {
    const startTimeString = formatTo12HourTime(new Date(booking.startDate))
    const endTimeString = formatTo12HourTime(new Date(booking.endDate))
    return [startTimeString, endTimeString].join(' to ')
  })

  const selectedIndices = selectedAvailabilities
    .filter((availability: ClientAvailability | MerchantAvailability) =>
      moment(availability.startDate).isSame(moment(currentDate), 'day')
    )
    .reduce(
      (
        indices: number[],
        availability: ClientAvailability | MerchantAvailability
      ): number[] => {
        const availabilityStart = DateTime.fromJSDate(
          new Date(availability.startDate)
        )

        const availabilityEnd = DateTime.fromJSDate(
          new Date(availability.endDate)
        )

        const availabilityInterval = Interval.fromDateTimes(
          availabilityStart,
          availabilityEnd
        )

        const foundIndex = bookings.reduce(
          (bookingIndex, booking, index): number => {
            const bookingInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(new Date(booking.startDate)),
              DateTime.fromJSDate(new Date(booking.endDate))
            )

            if (bookingInterval.equals(availabilityInterval)) {
              return index
            } else {
              return bookingIndex
            }
          },
          -1
        )

        return foundIndex >= 0 ? indices.concat(foundIndex) : indices
      },
      []
    )

  const addBooking = (optionIndex: number): void => {
    const isMaxSelectionReached =
      selectedAvailabilities.length === MAXIMUM_AVAILABILITIES

    if (isMaxSelectionReached || selectedIndices.includes(optionIndex)) {
      return
    }

    onAddAvailability(
      new Date(bookings[optionIndex].startDate),
      new Date(bookings[optionIndex].endDate)
    )
  }

  const availabilityOptions = selectedAvailabilities.map(
    (availability: ClientAvailability | MerchantAvailability) =>
      // FIXME - need a better way
      formatDateIntervalString(
        new Date(availability.startDate),
        new Date(availability.endDate)
      )
  )

  const availabilitiesContent = availabilityOptions.length ? (
    <SelectedAvailabilitiesList
      options={availabilityOptions}
      onDeleteOption={onDeleteAvailability}
    />
  ) : (
    <NoAvailabilitiesCard />
  )

  return (
    <AddAvailabilityDialogue
      className={className}
      headerChildren={headerChildren}
      footerChildren={footerChildren}
      onCloseDialogue={onCloseDialogue}
    >
      <Row>
        <DatePickerTable
          todayDate={todayDate}
          currentDate={currentDate}
          startDate={startDate}
          endDate={endDate}
          onSelectDate={onSelectDate}
        />
        <SelectableAvailabilitiesList
          emptyListMessage={NO_BOOKINGS_FOR_THIS_DATE_LABEL}
          options={bookingOptions}
          onSelectOption={addBooking}
          selectedIndices={selectedIndices}
        />
      </Row>
      <Wrapper>{availabilitiesContent}</Wrapper>
    </AddAvailabilityDialogue>
  )
}
