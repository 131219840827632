import React from 'react'
import Icon from '../Icon'
import { ReactComponent as EmojiComponent } from './assets/happy-face-emoji.svg'

export const HappyFaceIcon = Icon

export interface IHappyFaceIcon {
  className?: string
}
export default ({ className = '' }: IHappyFaceIcon) => (
  <HappyFaceIcon className={className}>
    <EmojiComponent />
  </HappyFaceIcon>
)
