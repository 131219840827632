import React, { CSSProperties } from 'react'
import makeAnimated from 'react-select/animated'
import { SelectComponents } from 'react-select/src/components'
import styled from 'styled-components'
import { AdditionalParams } from 'ui/components/dropdown/Dropdown'
import { SEARCH_DROPDOWN_PLACEHOLDER } from '../../../../constants'
import OptionCard from '../../../components/cards/Card'
import AsyncSearchDropdown, {
  AsyncDropdownProps,
} from '../../../components/dropdown/AsyncDropdown'

export type SearchDropdownProps = AsyncDropdownProps
export interface SearchDropdownState {
  isFocused: boolean
}

export const SearchOptionCard = styled(OptionCard)`
  padding: 0;
  .menu {
    padding: 7px 10px;
  }
  h1 {
    margin: 0;
    display: inline-block;
    font-weight: bold;
  }
  h2 {
    margin: 0;
    display: inline-block;
    font-weight: normal;
  }
  h3 {
    margin: 0;
    margin-left: 8px;
    display: inline-block;
    opacity: 0.7;
  }
  h4 {
    margin: 0;
    margin-top: 5px;
    opacity: 0.7;
  }
`

export const searchDropdownStyle = {
  container: (provided: CSSProperties) => ({
    ...provided,
    border: 'none',
  }),
  control: (provided: CSSProperties, state: SearchDropdownState) => ({
    ...provided,
    border: 'none',
    width: '450px',
    padding: '3px 0',
    boxShadow: state.isFocused ? '0 0 0 1px #e1e1e1' : 'none',
  }),
  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided: CSSProperties, state: SearchDropdownState) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ededed' : 'white',
    cursor: state.isFocused ? 'pointer' : 'default',
    color: 'grey',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    margin: '20px 0',
    width: '508px',
    right: '-10px',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    padding: '0',
    borderRadius: '5px',
  }),
  multiValue: (provided: CSSProperties) => ({
    ...provided,
    borderRadius: '5px',
    margin: '2px 3px 2px 0',
    backgroundColor: '#ededed',
  }),
  multiValueLabel: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '100%',
    padding: '6px',
    paddingLeft: '10px',
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided: CSSProperties) => ({
    ...provided,
    padding: '0 3px',
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
    padding: '0 3px',
  }),
}

export const animatedComponents: SelectComponents<AdditionalParams> = makeAnimated()

export default (props: SearchDropdownProps) => (
  <AsyncSearchDropdown
    isSearchable={true}
    isClearable={true}
    isMulti={true}
    placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
    components={animatedComponents}
    {...props}
    styles={{ ...searchDropdownStyle, ...props.styles }}
  />
)
