import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import CloseButton from '../../buttons/CloseButton'

export interface IHeader {
  className?: string
  children?: React.ReactNode | null
  onClose?: () => void
}

export const Header = styled.div`
  align-items: center;
  background-color: #fff9f9;
  color: #344e63;
  display: flex;
  font-size: 24px;
  min-height: 60px;
  position: relative;
`

const Body = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const Footer = styled.div`
  line-height: 0px;
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 1;
`

export default ({
  className = '',
  children = null,
  onClose = noop,
}: IHeader) => (
  <Header className={className}>
    <Body>{children}</Body>
    <Footer>
      <CloseButton onClick={onClose} />
    </Footer>
  </Header>
)
