import { track } from 'lib/analytics'
import { AnalyticsEvent, AnalyticsEventData } from 'lib/analytics/types'
import api from '../../api/jobs'
import { GetJobsFilter, Job } from '../../api/jobs/types'
import availabilitiesActions from '../availabilities/actions'
import { Dispatch, GetState } from '../index'
import profilesActions, {
  updateClientProfile,
  updateMerchantProfile,
} from '../profiles/actions'
import { getSearchSelector } from '../search/selectors'
import { getJobByJobIdSelector } from './selectors'

export enum ACTION_TYPES {
  GET_JOBS_BY_FILTER_INIT = 'GET_JOBS_BY_FILTER_INIT',
  GET_JOBS_BY_FILTER_FAILURE = 'GET_JOBS_BY_FILTER_FAILURE',
  GET_JOBS_BY_FILTER_SUCCESS = 'GET_JOBS_BY_FILTER_SUCCESS',

  SCHEDULE_JOB_SUCCESS = 'SCHEDULE_JOB_SUCCESS',
  SCHEDULE_JOB_FAILURE = 'SCHEDULE_JOB_FAILURE',
  SCHEDULE_JOB = 'SCHEDULE_JOB',

  TOGGLE_JOB_URGENCY_INIT = 'TOGGLE_JOB_URGENCY_INIT',
  TOGGLE_JOB_URGENCY_FAILURE = 'TOGGLE_JOB_URGENCY_FAILURE',
  TOGGLE_JOB_URGENCY_SUCCESS = 'TOGGLE_JOB_URGENCY_SUCCESS',

  UPDATE_JOBS = 'UPDATE_JOBS',
  UPDATE_JOB = 'UPDATE_JOB',

  GET_SINGLE_JOB_INIT = 'GET_SINGLE_JOB_INIT',
  GET_SINGLE_JOB_FAILURE = 'GET_SINGLE_JOB_FAILURE',
}

export interface IToggleJobUrgencyStatusState {
  jobId: number
}

export type Action =
  | {
      type: ACTION_TYPES.GET_JOBS_BY_FILTER_INIT
    }
  | {
      type: ACTION_TYPES.GET_JOBS_BY_FILTER_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.GET_JOBS_BY_FILTER_SUCCESS }
  | {
      type: ACTION_TYPES.SCHEDULE_JOB
      data: { job: Job }
    }
  | {
      type: ACTION_TYPES.TOGGLE_JOB_URGENCY_SUCCESS
    }
  | {
      type: ACTION_TYPES.TOGGLE_JOB_URGENCY_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.TOGGLE_JOB_URGENCY_INIT
      data: { jobId: number }
    }
  | {
      type: ACTION_TYPES.UPDATE_JOB
      data: { job: Job }
    }
  | {
      type: ACTION_TYPES.UPDATE_JOBS
      data: { jobs: Job[] }
    }
  | {
      type: ACTION_TYPES.SCHEDULE_JOB_SUCCESS
      data: { job: Job }
    }
  | {
      type: ACTION_TYPES.SCHEDULE_JOB_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.GET_SINGLE_JOB_INIT
    }
  | {
      type: ACTION_TYPES.GET_SINGLE_JOB_FAILURE
    }

export const getJobsByFilter = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_JOBS_BY_FILTER_INIT })

    const {
      startDate,
      endDate,
      clientIds,
      merchantIds,
      orderIds,
      jobIds,
    } = getSearchSelector(getState()).filter

    const filter: GetJobsFilter = {
      start_date: startDate,
      end_date: endDate,
      client_ids: clientIds,
      merchant_ids: merchantIds,
      order_ids: orderIds,
      job_ids: jobIds,
    }

    const {
      clientAvailabilities,
      clientProfiles,
      jobs,
      merchantAvailabilities,
      merchantProfiles,
    } = await api.getJobsByFilter(filter)

    dispatch(
      availabilitiesActions.updateClientAvailabilities(clientAvailabilities)
    )
    dispatch(
      availabilitiesActions.updateMerchantAvailabilities(merchantAvailabilities)
    )
    dispatch(profilesActions.updateClientProfiles(clientProfiles))
    dispatch(profilesActions.updateMerchantProfiles(merchantProfiles))
    dispatch(updateJobs(jobs))
    dispatch({ type: ACTION_TYPES.GET_JOBS_BY_FILTER_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_JOBS_BY_FILTER_FAILURE,
      data: { error },
    })
  }
}

export const scheduleJob = (job: Job) => async (dispatch: Dispatch) => {
  try {
    const {
      job: updatedJob,
      clientProfile,
      merchantProfile,
    } = await api.patchJobByJobId(job, job.id)
    const trackEventData: AnalyticsEventData = {
      clientId: job.clientId,
      jobId: job.id,
      merchantId: job.merchantId,
      startDate: job.startDate,
      endDate: job.endDate,
    }
    track(AnalyticsEvent.JobScheduled, trackEventData)
    dispatch(profilesActions.updateClientProfile(clientProfile))
    dispatch(profilesActions.updateMerchantProfile(merchantProfile))
    dispatch(scheduleJobSuccess(updatedJob))
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.SCHEDULE_JOB_FAILURE,
      data: { error },
    })
  }
}

export const toggleJobUrgencyStatus = (jobId: number) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    dispatch({ type: ACTION_TYPES.TOGGLE_JOB_URGENCY_INIT, data: { jobId } })
    const job = getJobByJobIdSelector(getState())(jobId)
    if (!job) {
      throw new Error('The requested Job entity cannot be found')
    }

    const toggledJob = {
      ...job,
      isUrgent: !job.isUrgent,
    }
    const { job: updatedJob } = await api.patchJobByJobId(
      toggledJob,
      toggledJob.id
    )
    dispatch(updateJob(updatedJob))
    dispatch({ type: ACTION_TYPES.TOGGLE_JOB_URGENCY_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_JOB_URGENCY_FAILURE,
      data: { error },
    })
  }
}

export const getJobByJobId = (jobId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(getJobByJobIdInit())

    const { job, clientProfile, merchantProfile } = await api.getJobByJobId(
      jobId
    )

    dispatch(updateJob(job))
    dispatch(updateClientProfile(clientProfile))
    dispatch(updateMerchantProfile(merchantProfile))
  } catch (e) {
    dispatch(getJobByJobIdFailure())
  }
}

const getJobByJobIdInit = (): Action => ({
  type: ACTION_TYPES.GET_SINGLE_JOB_INIT,
})

const getJobByJobIdFailure = (): Action => ({
  type: ACTION_TYPES.GET_SINGLE_JOB_FAILURE,
})

export const scheduleJobSuccess = (job: Job): Action => ({
  type: ACTION_TYPES.SCHEDULE_JOB_SUCCESS,
  data: { job },
})

export const updateJob = (job: Job): Action => ({
  type: ACTION_TYPES.UPDATE_JOB,
  data: { job },
})

export const updateJobs = (jobs: Job[]): Action => ({
  type: ACTION_TYPES.UPDATE_JOBS,
  data: { jobs },
})

export default {
  getJobsByFilter,
  scheduleJob,
  toggleJobUrgencyStatus,
  updateJob,
  updateJobs,
}
