import { load, remove } from 'react-cookies'
import { RemoteData, cata } from 'remote-data-ts'
import { SessionUser } from '../../api/session/types'
import { ACTION_TYPES, Action } from './actions'

const SESSION_TOKEN_KEY = 'session_token'

export interface IAuthenticatedState {
  type: 'authenticated'
  token: string
  user: SessionUser
}

export interface IUnauthenticatedState {
  type: 'unauthenticated'
}

export type ReduxState = IAuthenticatedState | IUnauthenticatedState

export type RemoteDataState = RemoteData<ReduxState, Error>

export const setSessionDataToStorage = (sessionToken?: string): void => {
  if (sessionToken) {
    localStorage.setItem(SESSION_TOKEN_KEY, sessionToken)
  } else {
    localStorage.removeItem(SESSION_TOKEN_KEY)
  }
}

export const clearCachedSSOSession = (): void => {
  remove('access_token', { domain: 'setter.com' })
}

export const getCachedSessionToken = (): string | null => {
  return localStorage.getItem(SESSION_TOKEN_KEY)
}

export const getCachedSSOSessionToken = (): string | null => {
  return load('access_token')
}

export const initialRemoteDataState: RemoteDataState = RemoteData.notAsked()

export const initialReduxState: ReduxState = {
  type: 'unauthenticated',
}

export const reduxReducer = (
  state: ReduxState = initialReduxState,
  action: Action
): ReduxState => {
  if (action.type === ACTION_TYPES.LOGIN_USER_SUCCESS) {
    setSessionDataToStorage(action.data.token)

    return {
      type: 'authenticated',
      token: action.data.token,
      user: action.data.user,
    }
  }

  if (action.type === ACTION_TYPES.LOGOUT_USER_SUCCESS) {
    clearCachedSSOSession()
    return {
      type: 'unauthenticated',
    }
  }

  if (action.type === ACTION_TYPES.VALIDATE_SESSION_SUCCESS) {
    if (action.data.user) {
      return {
        type: 'authenticated',
        token: action.data.token,
        user: action.data.user,
      }
    } else {
      return {
        type: 'unauthenticated',
      }
    }
  }

  return state
}

export const remoteDataReducer = (
  state: RemoteDataState = initialRemoteDataState,
  action: Action
): RemoteDataState => {
  return cata<ReduxState, Error, RemoteDataState>({
    notAsked: (): RemoteDataState => {
      if (
        action.type === ACTION_TYPES.LOGIN_USER_INIT ||
        action.type === ACTION_TYPES.VALIDATE_SESSION_INIT
      ) {
        return RemoteData.loading()
      }

      return state
    },
    loading: (): RemoteDataState => {
      if (
        action.type === ACTION_TYPES.LOGIN_USER_SUCCESS ||
        action.type === ACTION_TYPES.LOGOUT_USER_SUCCESS ||
        action.type === ACTION_TYPES.VALIDATE_SESSION_SUCCESS
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }

      if (action.type === ACTION_TYPES.UNAUTHENTICATED_SESSION_INIT) {
        return RemoteData.success({
          type: 'unauthenticated',
        })
      }

      if (action.type === ACTION_TYPES.LOGIN_USER_FAILURE) {
        return RemoteData.failure(action.data.error)
      }
      return state
    },
    success: (sessionState: ReduxState): RemoteDataState => {
      return RemoteData.success(reduxReducer(sessionState, action))
    },
    failure: (error: Error): RemoteDataState => {
      return RemoteData.notAsked()
    },
  })(state)
}

export default remoteDataReducer
