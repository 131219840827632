import React from 'react'
import Icon from '../Icon'
import { ReactComponent as IconComponent } from './assets/search.svg'

export const SearchIcon = Icon

export interface ISearchIcon {
  className?: string
}
export default ({ className }: ISearchIcon) => (
  <SearchIcon className={className}>
    <IconComponent />
  </SearchIcon>
)
