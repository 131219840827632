import { JobsFilter } from 'lib/api/jobs/types'
import { cata } from 'remote-data-ts'
import { createSelector } from 'reselect'
import { IState } from '../index'
import { ReduxState, initialReduxState } from './index'

export const getSearchSelector = (state: IState): ReduxState => {
  const defaultState = (): ReduxState => initialReduxState

  return cata({
    loading: defaultState,
    notAsked: defaultState,
    failure: defaultState,
    success: (reduxState: ReduxState) => reduxState,
  })(state.search)
}

export const getSearchFilterSelector = createSelector(
  getSearchSelector,
  (searchState): JobsFilter => searchState.filter
)
export default {
  getSearchSelector,
  getSearchFilterSelector,
}
