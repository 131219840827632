import { noop } from 'lodash'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import LeftArrowButton from '../../../components/buttons/LeftArrowButton'
import _RightArrowButton from '../../../components/buttons/RightArrowButton'
import MonthPickerTable, {
  TableColumn as _TableColumn,
  TableRow as _TableRow,
} from '../../../components/tables/Table'

const TableRow = styled(_TableRow)`
  margin-bottom: 25px;
`

const MonthColumn = styled(_TableColumn)`
  font-size: 16px;
  flex: 1 1 auto;
`

const RightArrowButton = styled(_RightArrowButton)`
  margin-left: 20px;
`

export interface IMonthPickerTable {
  className?: string
  currentDate?: Date
  startDate?: Date
  endDate?: Date
  onSelectPreviousMonth?: (date: Date) => void
  onSelectNextMonth?: (date: Date) => void
}

export const NavigationColumn = styled(_TableColumn)`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
`

export default ({
  className = '',
  currentDate = new Date(),
  startDate,
  endDate,
  onSelectNextMonth = noop,
  onSelectPreviousMonth = noop,
}: IMonthPickerTable) => {
  const month = moment(currentDate).format('MMMM YYYY')
  const previousMonthStartDate = moment(currentDate)
    .clone()
    .subtract(1, 'month')
    .startOf('month')
    .toDate()
  const nextMonthStartDate = moment(currentDate)
    .clone()
    .add(1, 'month')
    .startOf('month')
    .toDate()

  const showLeftArrowButton =
    startDate &&
    moment(startDate).isSameOrBefore(moment(previousMonthStartDate), 'month')

  const showRightArrowButton =
    endDate &&
    moment(nextMonthStartDate).isSameOrBefore(moment(endDate), 'month')

  return (
    <MonthPickerTable className={className}>
      <TableRow>
        <MonthColumn>{month}</MonthColumn>
        <NavigationColumn>
          {showLeftArrowButton && (
            <LeftArrowButton
              onClick={() => onSelectPreviousMonth(previousMonthStartDate)}
            />
          )}
          {showRightArrowButton && (
            <RightArrowButton
              onClick={() => onSelectNextMonth(nextMonthStartDate)}
            />
          )}
        </NavigationColumn>
      </TableRow>
    </MonthPickerTable>
  )
}
