import styled from 'styled-components'
import { TableRow } from '../../../components/tables/Table'

export const DetailRow = styled(TableRow)`
  align-items: flex-start;
  border-bottom: 2px solid #e1e1e1;
  width: calc(100% - 80px);
  padding: 0 40px;
`

export default DetailRow
