import { noop } from 'lodash'
import React, { useState } from 'react'
import DatePickerTable from '../../../composites/tables/DatePickerTable'

export interface IDatePickerTable {
  className?: string
  todayDate?: Date
  currentDate?: Date
  startDate?: Date
  endDate?: Date
  onSelectDate?: (selectedDate: Date) => void
}

export default ({
  className = '',
  todayDate,
  currentDate = new Date(),
  startDate,
  endDate,
  onSelectDate = noop,
}: IDatePickerTable) => {
  const [selectedDate, setSelectedDate] = useState((): Date => currentDate)
  const thisOnSelectDate = (date: Date) => {
    setSelectedDate(date)
    onSelectDate(date)
  }

  return (
    <DatePickerTable
      className={className}
      todayDate={todayDate}
      currentDate={selectedDate}
      selectedDate={selectedDate}
      startDate={startDate}
      endDate={endDate}
      onSelectDate={thisOnSelectDate}
      onSelectNextMonth={thisOnSelectDate}
      onSelectPreviousMonth={thisOnSelectDate}
    />
  )
}
