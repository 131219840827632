import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { SEND_USERS_NOTIFICATIONS } from '../../../../constants'
import _PrimaryButton from '../../../components/buttons/PrimaryButton'
import ScheduleJobStep2Card from '../../../components/cards/Card'
import NotificationSettingsCard from '../../../composites/cards/NotificationSettingsCard'

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

const PreviousButton = styled(_PrimaryButton)`
  margin-right: 30px;
`

const NextButton = styled(_PrimaryButton)``

const ClientNotificationSettingsCard = styled(NotificationSettingsCard)`
  margin-right: 20px;
`

const MerchantNotificationSettingsCard = styled(NotificationSettingsCard)``

export interface IScheduleJobStep2Card {
  className?: string
  onNextStep?: () => void
  onPreviousStep?: () => void
  onSelectClientNotificationSettings?: (index: number) => void
  onSelectMerchantNotificationSettings?: (index: number) => void
  selectedClientNotificationSettingIndices?: number[]
  selectedMerchantNotificationSettingIndices?: number[]
}

export default ({
  className = '',
  onNextStep = noop,
  onPreviousStep = noop,
  onSelectClientNotificationSettings = noop,
  onSelectMerchantNotificationSettings = noop,
  selectedClientNotificationSettingIndices = [],
  selectedMerchantNotificationSettingIndices = [],
}: IScheduleJobStep2Card) => {
  return (
    <ScheduleJobStep2Card className={className}>
      <Column>
        <h2>{SEND_USERS_NOTIFICATIONS} </h2>
        <Row>
          <ClientNotificationSettingsCard
            title={'Client'}
            options={['SMS', 'E-mail']}
            onSelectOption={onSelectClientNotificationSettings}
            selectedIndices={selectedClientNotificationSettingIndices}
          />
          <MerchantNotificationSettingsCard
            title={'Pro'}
            options={['SMS', 'E-mail']}
            onSelectOption={onSelectMerchantNotificationSettings}
            selectedIndices={selectedMerchantNotificationSettingIndices}
          />
        </Row>
        <Navigation>
          <PreviousButton isSelected={false} onClick={() => onPreviousStep()}>
            Back
          </PreviousButton>
          <NextButton isSelected={true} onClick={() => onNextStep()}>
            Next
          </NextButton>
        </Navigation>
      </Column>
    </ScheduleJobStep2Card>
  )
}
