import * as rt from 'runtypes'

export const jobParser = rt.Record({
  // FIXME  - id > jobId
  id: rt.Number,
  title: rt.String,
  description: rt.String,
  // FIXME: an incoming job from the server will never have
  // a start and end date. It will always be "null".
  startDate: rt.String.Or(rt.Undefined).Or(rt.Null),
  endDate: rt.String.Or(rt.Undefined).Or(rt.Null),
  approvedDate: rt.String,
  merchantId: rt.Number,
  clientId: rt.Number,
  isUrgent: rt.Boolean,
  type: rt.String,
})

export type Job = rt.Static<typeof jobParser>

export const clientAvailabilityParser = rt.Record({
  availabilityId: rt.Number,
  clientId: rt.Number,
  jobId: rt.Number,
  startDate: rt.String,
  endDate: rt.String,
})

export const merchantAvailabilityParser = rt.Record({
  availabilityId: rt.Number,
  merchantId: rt.Number,
  jobId: rt.Number,
  startDate: rt.String,
  endDate: rt.String,
})

export default {
  jobParser,
  clientAvailabilityParser,
  merchantAvailabilityParser,
}
