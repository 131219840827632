import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { JOB_NOT_SCHEDULED_LABEL } from '../../../../constants'
import {
  canJobBeScheduledByJobIdSelector,
  getMatchStatusByJobIdSelector,
  getMatchStatusTypeByJobIdSelector,
  getScheduleJobInstructionsByJobIdSelector,
} from '../../../../lib/redux/availabilities/selectors'
import { getJobByJobIdSelector } from '../../../../lib/redux/jobs/selectors'
import { formatDateIntervalString } from '../../../../utils/format-strings'
import ScheduleJobCard from '../../../composites/cards/ScheduleJobCard'
import ScheduleJobDialogueModal from '../../../composites/modals/ScheduleJobDialogueModal'

export interface IScheduleJobCard {
  className?: string
  jobId: number
}

export default ({ className = '', jobId }: IScheduleJobCard) => {
  const [
    isScheduleJobDialogueModalShowing,
    setScheduleModalDialogueShowing,
  ] = useState((): boolean => false)

  const getJobByJobId = useSelector(getJobByJobIdSelector)
  const getScheduleJobInstructionsByJobId = useSelector(
    getScheduleJobInstructionsByJobIdSelector
  )
  const getMatchStatusTypeByJobId = useSelector(
    getMatchStatusTypeByJobIdSelector
  )
  const getMatchStatusByJobId = useSelector(getMatchStatusByJobIdSelector)
  const canJobBeScheduled = useSelector(canJobBeScheduledByJobIdSelector(jobId))
  const job = getJobByJobId(jobId)

  if (!job) {
    return <div>job not found</div>
  }

  const jobScheduledDate =
    job.startDate && job.endDate
      ? formatDateIntervalString(new Date(job.startDate), new Date(job.endDate))
      : JOB_NOT_SCHEDULED_LABEL
  const jobMatchStatusType = getMatchStatusTypeByJobId(jobId)
  const jobMatchStatus = getMatchStatusByJobId(jobId)

  const nextSteps = getScheduleJobInstructionsByJobId(jobId)
  const onScheduleJob = () => setScheduleModalDialogueShowing(true)

  return (
    <>
      <ScheduleJobDialogueModal
        isShowing={isScheduleJobDialogueModalShowing}
        jobId={jobId}
        onCloseDialogue={() => setScheduleModalDialogueShowing(false)}
      />
      <ScheduleJobCard
        className={className}
        jobScheduledDate={jobScheduledDate}
        jobMatchStatusType={jobMatchStatusType}
        jobMatchStatus={jobMatchStatus}
        nextSteps={nextSteps}
        onScheduleJob={onScheduleJob}
        canJobBeScheduled={canJobBeScheduled}
      />
    </>
  )
}
