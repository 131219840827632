import * as api from '../../api/profiles'
import { ClientProfile, MerchantProfile } from '../../api/profiles/types'
import { Dispatch, GetState } from '../index'
import { getClientProfileByClientIdSelector } from './selectors'

export enum ACTION_TYPES {
  TOGGLE_CLIENT_VIP_STATUS_FAILURE = 'TOGGLE_CLIENT_VIP_STATUS_FAILURE',
  TOGGLE_CLIENT_VIP_STATUS_INIT = 'TOGGLE_CLIENT_VIP_STATUS_INIT',
  TOGGLE_CLIENT_VIP_STATUS_SUCCESS = 'TOGGLE_CLIENT_VIP_STATUS_SUCCESS',
  UPDATE_CLIENT_PROFILE_BY_CLIENT_ID = 'UPDATE_CLIENT_PROFILE_BY_CLIENT_ID',
  UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE',
  UPDATE_CLIENT_PROFILES = 'UPDATE_CLIENT_PROFILES',
  UPDATE_MERCHANT_PROFILE = 'UPDATE_MERCHANT_PROFILE',
  UPDATE_MERCHANT_PROFILES = 'UPDATE_MERCHANT_PROFILES',
}

export type Action =
  | {
      type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_FAILURE
    }
  | {
      type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_INIT
      data: { clientId: number }
    }
  | {
      type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_SUCCESS
    }
  | {
      type: ACTION_TYPES.UPDATE_CLIENT_PROFILE_BY_CLIENT_ID
      data: { clientProfile: ClientProfile }
    }
  | {
      type: ACTION_TYPES.UPDATE_CLIENT_PROFILE
      data: { clientProfile: ClientProfile }
    }
  | {
      type: ACTION_TYPES.UPDATE_CLIENT_PROFILES
      data: { clientProfiles: ClientProfile[] }
    }
  | {
      type: ACTION_TYPES.UPDATE_MERCHANT_PROFILE
      data: { merchantProfile: MerchantProfile }
    }
  | {
      type: ACTION_TYPES.UPDATE_MERCHANT_PROFILES
      data: { merchantProfiles: MerchantProfile[] }
    }

const toggleClientVipStatusSuccess = (clientId: number): Action => ({
  type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_SUCCESS,
})

export const toggleClientVIPStatus = (clientId: number) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    dispatch({
      type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_INIT,
      data: { clientId },
    })
    const client = getClientProfileByClientIdSelector(getState())(clientId)

    if (!client) {
      throw new Error('The requested Job entity cannot be found')
    }

    const updatedClient = {
      ...client,
      isVip: !client.isVip,
    }

    const { profile: updatedClientProfile } = await api.patchClientByClientId(
      updatedClient,
      updatedClient.clientId
    )

    dispatch(updateClientProfile(updatedClientProfile))
    dispatch(toggleClientVipStatusSuccess(updatedClientProfile.clientId))
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_CLIENT_VIP_STATUS_FAILURE,
      data: { error },
    })
  }
}

export const updateClientProfile = (clientProfile: ClientProfile): Action => ({
  type: ACTION_TYPES.UPDATE_CLIENT_PROFILE,
  data: { clientProfile },
})

export const updateClientProfiles = (
  clientProfiles: ClientProfile[]
): Action => ({
  type: ACTION_TYPES.UPDATE_CLIENT_PROFILES,
  data: { clientProfiles },
})

export const updateMerchantProfile = (
  merchantProfile: MerchantProfile
): Action => ({
  type: ACTION_TYPES.UPDATE_MERCHANT_PROFILE,
  data: { merchantProfile },
})

export const updateMerchantProfiles = (
  merchantProfiles: MerchantProfile[]
): Action => ({
  type: ACTION_TYPES.UPDATE_MERCHANT_PROFILES,
  data: { merchantProfiles },
})

export default {
  toggleClientVIPStatus,
  updateClientProfile,
  updateClientProfiles,
  updateMerchantProfile,
  updateMerchantProfiles,
}
