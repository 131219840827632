import React from 'react'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../../../lib/redux/session/actions'
import AuthenticatedView from '../../../components/views/AuthenticatedView'

export interface IAuthenticatedView {
  children?: React.ReactNode[] | React.ReactNode | null
}

export default ({ children }: IAuthenticatedView) => {
  const dispatch = useDispatch()
  const onClickLogout = () => dispatch(logoutUser())

  return <AuthenticatedView children={children} onClickLogout={onClickLogout} />
}
