import { noop } from 'lodash'
import React from 'react'
import CheckBoxIcon from '../../icons/CheckBoxIcon'
import ToggleButton, { IToggleButton } from '../ToggleButton'

export interface ICheckBoxButton extends IToggleButton {
  onClick?: () => void
}

export default ({ onClick = noop, isSelected }: ICheckBoxButton) => (
  <ToggleButton onClick={onClick} isSelected={isSelected}>
    {isSelected ? <CheckBoxIcon /> : null}
  </ToggleButton>
)
