import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import _CrossIcon from '../../icons/CrossIcon'
import SecondaryButton from '../SecondaryButton'

export interface IDeleteLabelledButton {
  className?: string
  children?: React.ReactNode | null
  isSelected?: boolean
  onClick?: () => void
}

export const DeleteLabelledButton = styled(SecondaryButton)`
  background-color: ${props =>
    props.isSelected ? '#91D5FF' : 'rgba(0, 0, 0, 0.02)'};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => (props.isSelected ? '#344E63' : 'auto')}
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 14px;
  padding-right: 5px;
  width: 100%;

  span {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

export const CrossIcon = styled(_CrossIcon)`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  margin-left: 10px;
  margin-right: 0;
  justify-content: center;
`

export default ({
  className = '',
  children = null,
  isSelected = false,
  onClick = noop,
}: IDeleteLabelledButton) => (
  <DeleteLabelledButton
    className={className}
    onClick={onClick}
    isSelected={isSelected}
  >
    <span>
      {children}
      <CrossIcon />
    </span>
  </DeleteLabelledButton>
)
