import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import SecondaryButton from '../SecondaryButton'

export interface IToggleLabelledButton {
  className?: string
  children?: React.ReactNode | null
  isSelected?: boolean
  onSelectOption?: () => void
}

export const ToggleLabelledButton = styled(SecondaryButton)`
  cursor: pointer;
  font-size: 11px;
`

export default ({
  className = '',
  children = null,
  isSelected = false,
  onSelectOption = noop,
}: IToggleLabelledButton) => (
  <ToggleLabelledButton
    className={className}
    isSelected={isSelected}
    onClick={onSelectOption}
  >
    {children}
  </ToggleLabelledButton>
)
