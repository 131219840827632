import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Button, { IButtonProps } from '../Button'

export interface ISecondaryButton extends IButtonProps {}

export const SecondaryButton = styled(Button)`
  font-size: 12px;
  height: 32px;
  width: 160px;
`

export default ({
  className = '',
  children = null,
  isSelected = false,
  onClick = noop,
}: IButtonProps) => (
  <SecondaryButton
    className={className}
    isSelected={isSelected}
    onClick={onClick}
  >
    {children}
  </SecondaryButton>
)
