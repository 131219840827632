import React from 'react'
import styled from 'styled-components'

export interface ITable {
  className?: string | ''
  headerChildren?: React.ReactNode[] | React.ReactNode | null
  children?: React.ReactNode[] | React.ReactNode | null
  footerChildren?: React.ReactNode[] | React.ReactNode | null
}

export const TableRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

export const Table = styled(TableRow)`
  width: 100%;
`

export const TableHeading = styled(TableRow)``

export const TableBody = styled(TableColumn)``

export const TableFooter = styled(TableRow)``

export default ({
  className = '',
  headerChildren = null,
  children = null,
  footerChildren = null,
}: ITable) => {
  return (
    <Table className={className}>
      <TableColumn>
        {headerChildren}
        {children}
        {footerChildren}
      </TableColumn>
    </Table>
  )
}
