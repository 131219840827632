import styled from 'styled-components'

export interface ITodaysDateLabel {
  isSelected: boolean
}

export default styled.label<ITodaysDateLabel>`
  display: ${props => (props.isSelected ? 'block' : 'none')};
  color: #fa5860;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: -15px;
  z-index: 1;
`
