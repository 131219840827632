import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import _DeleteLabelledButton from '../../../components/buttons/DeleteLabelledButton'

export interface List {
  className?: string
  onDeleteOption?: (index: number) => void
  options?: string[]
  selectedIndices?: number[]
}

export const DeleteLabelledButton = styled(_DeleteLabelledButton)`
  width: 100%;
`

export const List = styled.div`
  color: #344e63;
  font-size: 14px;

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 100%;

    li {
      margin-bottom: 10px;
    }
  }
`

export default ({
  className = '',
  onDeleteOption = noop,
  options = [],
  selectedIndices = [],
}: List) => {
  const optionsContent = options.map((option: string, index: number) => (
    <li key={`list-${index}`}>
      <DeleteLabelledButton
        onClick={() => onDeleteOption(index)}
        isSelected={selectedIndices.includes(index)}
      >
        {option}
      </DeleteLabelledButton>
    </li>
  ))

  return (
    <List className={className}>
      <ul>{optionsContent}</ul>
    </List>
  )
}
