import React from 'react'
import Icon from '../Icon'
import { ReactComponent as CrossComponent } from './assets/cross.svg'

export const CrossIcon = Icon

export interface ICrossCircle {
  className?: string
}
export default ({ className }: ICrossCircle) => (
  <CrossIcon className={className}>
    <CrossComponent />
  </CrossIcon>
)
