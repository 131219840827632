import { RemoteData, cata } from 'remote-data-ts'
import {
  ClientAvailability,
  ClientBooking,
  MerchantAvailability,
  MerchantBooking,
} from '../../api/availabilities/types'
import { ACTION_TYPES, Action } from './actions'

export type ReduxState = {
  clients: ClientAvailability[]
  merchants: MerchantAvailability[]
  clientBookings: ClientBooking[]
  merchantBookings: MerchantBooking[]
}

export type RemoteDataState = RemoteData<ReduxState, Error>

export const initialReduxState: ReduxState = {
  clients: [],
  merchants: [],
  clientBookings: [],
  merchantBookings: [],
}

export const initialRemoteDataState: RemoteDataState = RemoteData.notAsked()

export const reduxReducer = (
  state: ReduxState = initialReduxState,
  action: Action
) => {
  if (action.type === ACTION_TYPES.REMOVE_CLIENT_AVAILABILITY) {
    return {
      ...state,
      clients: state.clients.filter(
        ({ availabilityId }) =>
          availabilityId !== action.data.clientAvailability.availabilityId
      ),
    }
  }

  if (action.type === ACTION_TYPES.REMOVE_MERCHANT_AVAILABILITY) {
    return {
      ...state,
      merchants: state.merchants.filter(
        ({ availabilityId }) =>
          availabilityId !== action.data.merchantAvailability.availabilityId
      ),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_CLIENT_AVAILABILITIES) {
    const availabilitiesIdList: number[] = action.data.clientAvailabilities.map(
      ({ availabilityId }) => availabilityId
    )

    return {
      ...state,
      clients: state.clients
        .filter(
          ({ availabilityId }) =>
            availabilitiesIdList.indexOf(availabilityId) < 0
        )
        .concat(action.data.clientAvailabilities),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_CLIENT_BOOKINGS) {
    const clientIdList: number[] = action.data.clientBookings.map(
      ({ clientId }) => clientId
    )

    return {
      ...state,
      clientBookings: state.clientBookings
        .filter(({ clientId }) => clientIdList.indexOf(clientId) < 0)
        .concat(action.data.clientBookings),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_MERCHANT_BOOKINGS) {
    const merchantIdList: number[] = action.data.merchantBookings.map(
      ({ merchantId }) => merchantId
    )

    return {
      ...state,
      merchantBookings: state.merchantBookings
        .filter(({ merchantId }) => merchantIdList.indexOf(merchantId) < 0)
        .concat(action.data.merchantBookings),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_MERCHANT_AVAILABILITIES) {
    const availabilitiesIdList: number[] = action.data.merchantAvailabilities.map(
      ({ availabilityId }) => availabilityId
    )

    return {
      ...state,
      merchants: state.merchants
        .filter(
          ({ availabilityId }) =>
            availabilitiesIdList.indexOf(availabilityId) < 0
        )
        .concat(action.data.merchantAvailabilities),
    }
  }
  return state
}

export const remoteDataReducer = (
  state: RemoteDataState = initialRemoteDataState,
  action: Action
): RemoteDataState => {
  return cata<ReduxState, Error, RemoteDataState>({
    notAsked: () => {
      if (
        action.type === ACTION_TYPES.REMOVE_CLIENT_AVAILABILITY ||
        action.type === ACTION_TYPES.REMOVE_MERCHANT_AVAILABILITY ||
        action.type === ACTION_TYPES.UPDATE_CLIENT_AVAILABILITIES ||
        action.type === ACTION_TYPES.UPDATE_MERCHANT_AVAILABILITIES
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }
      return state
    },
    loading: () => {
      return state
    },
    success: (reduxState: ReduxState) => {
      return RemoteData.success(reduxReducer(reduxState, action))
    },
    failure: (error: Error) => {
      return state
    },
  })(state)
}

export default remoteDataReducer
