import * as rt from 'runtypes'

export type SearchResult = rt.Static<typeof searchResultParser>

export const COORDINATION_CONSUMER = 'Coordination'

export enum RESOURCES {
  MERCHANT = 'Merchant',
  CLIENT = 'Client',
  JOB = 'Job',
  ORDER = 'Order',
  GLOBAL = 'Global',
}

export enum FILTER_RESOURCES {
  Merchant = 'merchant',
  Client = 'client',
  Job = 'job',
  Order = 'order',
}

const isFilterResource = (resource: string): resource is FILTER_RESOURCES =>
  resource.toLowerCase() === FILTER_RESOURCES.Merchant ||
  resource.toLowerCase() === FILTER_RESOURCES.Client ||
  resource.toLowerCase() === FILTER_RESOURCES.Job ||
  resource.toLowerCase() === FILTER_RESOURCES.Order

export const searchResultParser = rt.Record({
  id: rt.Number,
  id_string: rt.String,
  resource: rt.String.withConstraint(isFilterResource),
  header: rt.String,
  description: rt.String,
  service_region_id: rt.Number.Or(rt.Undefined),
  email: rt.String.Or(rt.Undefined),
  cellphone: rt.String.Or(rt.Undefined),
  comments: rt.String.Or(rt.Undefined),
  purchase_order_id: rt.String.Or(rt.Undefined),
  purchase_order_ids: rt.String.Or(rt.Undefined),
  order_id: rt.String.Or(rt.Undefined),
})

export const postSearchResultsParser = rt.Array(searchResultParser)
