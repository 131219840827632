import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { ReactComponent as CheckBoxComponent } from './assets/check-box.svg'

export const CheckBoxCircleIcon = styled(Icon)`
  align-items: center;
  background-color: #ff4856;
  border-radius: 100%;
  display: flex;
  justify-content: center;
`

export interface ICloseCircle {
  className?: string
}
export default ({ className }: ICloseCircle) => (
  <CheckBoxCircleIcon className={className}>
    <CheckBoxComponent />
  </CheckBoxCircleIcon>
)
