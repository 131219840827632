import React from 'react'
import styled from 'styled-components'
import { USER_TYPES } from '../../../../constants'
import _AvailabilityCard from '../../../containers/cards/AvailabilityCard'
import _ScheduleJobCard from '../../../containers/cards/ScheduleJobCard'

const CLIENT_AVAILABILITY_STATUS_LABEL = 'Client'
const MERCHANT_AVAILABILITY_STATUS_LABEL = 'Pro'

const AvailabilityCard = styled(_AvailabilityCard)`
  flex: 0 0 auto;
  width: 33%;
`

export interface IClientAndMerchantAvailabilityCard {
  className?: string
  clientDetailsChildren?: React.ReactNode[] | React.ReactNode | null
  clientId: number
  jobId: number
  merchantDetailsChildren?: React.ReactNode[] | React.ReactNode | null
  merchantId: number
}

export const ClientAndMerchantAvailabilityCard = styled.div`
  display: flex;
  width: 100%;
`

export const ScheduleJobCard = styled(_ScheduleJobCard)`
  flex: 1 1 auto;
`

export default ({
  className = '',
  clientDetailsChildren = null,
  clientId,
  jobId,
  merchantDetailsChildren = null,
  merchantId,
}: IClientAndMerchantAvailabilityCard) => (
  <ClientAndMerchantAvailabilityCard className={className}>
    <ScheduleJobCard jobId={jobId} />
    <AvailabilityCard
      headerChildren={CLIENT_AVAILABILITY_STATUS_LABEL}
      jobId={jobId}
      subHeaderChildren={clientDetailsChildren}
      userId={clientId}
      userType={USER_TYPES.CLIENT}
    />
    <AvailabilityCard
      headerChildren={MERCHANT_AVAILABILITY_STATUS_LABEL}
      jobId={jobId}
      subHeaderChildren={merchantDetailsChildren}
      userId={merchantId}
      userType={USER_TYPES.MERCHANT}
    />
  </ClientAndMerchantAvailabilityCard>
)
