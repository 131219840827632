import equal from 'fast-deep-equal/es6/react'
import { noop } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { JOB_NOT_SCHEDULED_LABEL } from '../../../../constants'
import { ResultsState } from '../../../../lib/redux/results'
import {
  formatDateIntervalString,
  formatDateString,
} from '../../../../utils/format-strings'
import FlagToggleButton from '../../../components/buttons/FlagToggleButton'
import StarToggleButton from '../../../components/buttons/StarToggleButton'
import _MatchStatusIcon from '../../icons/MatchStatusIcon'
import DetailRow from './DetailRow'

import { Button, Column, EmbeddableTable } from '@setter.inc/ui'

export interface IResultsTable {
  results: ResultsState[]
  onAddClientAvailability?: (jobId: number) => void
  onAddMerchantAvailability?: (jobId: number) => void
  onScheduleJob?: (jobId: number) => void
  onToggleJobUrgencyStatus?: (index: number) => void
  onToggleClientVIPStatus: (index: number) => void
  renderDetailRow: <T>(values: ResultsState) => React.ReactElement<T>
}

const Cell = styled.div`
  align-items: center;
  display: flex;
  margin: 0 10px;
  text-align: left;
`

const JustifiedCell = styled(Cell)`
  justify-content: center;
`

export const MatchStatusIcon = styled(_MatchStatusIcon)`
  margin-right: 10px;
`

const ResetSortButton = styled(Button)`
  font-size: 0.7em;
  line-height: 1.2em;
  margin-bottom: 2em;
  padding: 0;
`

export default ({
  results,
  onToggleJobUrgencyStatus = noop,
  onToggleClientVIPStatus,
  renderDetailRow,
}: IResultsTable) => {
  const prevResults = useRef(results)

  useEffect(() => {
    if (!equal(prevResults.current, results)) {
      prevResults.current = results
    }
  }, [prevResults, results])

  const columns = useMemo(
    (): Array<Column<ResultsState>> => [
      {
        CustomCell: ({ jobId }) => <JustifiedCell>{jobId}</JustifiedCell>,
        Header: 'Job ID',
        accessor: 'jobId',
      },
      {
        CustomCell: ({ jobTitle }) => <Cell>{jobTitle}</Cell>,
        Header: 'Job name',
        accessor: 'jobTitle',
      },
      {
        CustomCell: ({ jobType }) => <JustifiedCell>{jobType}</JustifiedCell>,
        Header: 'Job type',
        accessor: 'jobType',
      },
      {
        Header: 'ZenDesk requested',
        accessor: 'ticketCreatedDate',
        CustomCell: ({ ticketCreatedDate }) => {
          if (ticketCreatedDate) {
            return (
              <JustifiedCell>
                {formatDateString(ticketCreatedDate)}
              </JustifiedCell>
            )
          }

          return <>N/A</>
        },
        sortType: (
          row1: { values: { createdDate: string } },
          row2: { values: { createdDate: string } }
        ) => {
          const row1Date = new Date(row1.values.createdDate).getTime()
          const row2Date = new Date(row2.values.createdDate).getTime()

          if (row1Date > row2Date) {
            return 1
          } else if (row1Date < row2Date) {
            return -1
          }

          return 0
        },
        id: 'createdDate',
      },
      {
        CustomCell: ({ clientName }) => <Cell>{clientName}</Cell>,
        Header: 'Client name',
        accessor: 'clientName',
      },
      {
        CustomCell: ({ merchantName }) => <Cell>{merchantName}</Cell>,
        Header: 'Pro name',
        accessor: 'merchantName',
      },
      {
        Header: 'Status',
        accessor: 'jobMatchStatus',
        CustomCell: ({ jobMatchStatusType, jobMatchStatus }: ResultsState) => {
          return (
            <JustifiedCell>
              <MatchStatusIcon type={jobMatchStatusType} />
              {jobMatchStatus}
            </JustifiedCell>
          )
        },
      },
      {
        CustomCell: ({ jobStartDate, jobEndDate }: ResultsState) => (
          <Cell>
            {jobStartDate && jobEndDate
              ? formatDateIntervalString(jobStartDate, jobEndDate)
              : JOB_NOT_SCHEDULED_LABEL}
          </Cell>
        ),
        Header: 'Scheduled date',
      },
      {
        CustomCell: ({ isJobUrgent, jobId }: ResultsState) => {
          const onClickToggleFlagButton = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            event.stopPropagation()
            onToggleJobUrgencyStatus(jobId)
          }
          return (
            <JustifiedCell>
              <FlagToggleButton
                onClick={onClickToggleFlagButton}
                isSelected={isJobUrgent}
              />
            </JustifiedCell>
          )
        },
        Header: 'Urgent',
        accessor: ({ isJobUrgent }) => String(isJobUrgent),
        id: 'isJobUrgent',
      },
      {
        CustomCell: ({ clientId, isClientVIP }: ResultsState) => {
          const onClickStarToggleButton = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            event.stopPropagation()
            onToggleClientVIPStatus(clientId)
          }

          return (
            <JustifiedCell>
              <StarToggleButton
                onClick={onClickStarToggleButton}
                isSelected={isClientVIP}
              />
            </JustifiedCell>
          )
        },
        Header: 'VIP',
        accessor: ({ isClientVIP }) => String(isClientVIP),
        id: 'isClientVIP',
      },
    ],
    [onToggleClientVIPStatus, onToggleJobUrgencyStatus]
  )

  const subComponentRenderer = useCallback(
    row => <DetailRow>{renderDetailRow(row.values)}</DetailRow>,
    [renderDetailRow]
  )

  return (
    <EmbeddableTable
      data={prevResults.current}
      columns={columns}
      subComponentRenderer={subComponentRenderer}
      sortBy={[
        { id: 'isJobUrgent', desc: true },
        { id: 'isClientVIP', desc: true },
      ]}
      renderResetSortButton={({ onClick, children }) => (
        <ResetSortButton type="default" onClick={onClick}>
          {children}
        </ResetSortButton>
      )}
    />
  )
}
