import { noop } from 'lodash'
import React from 'react'
import { USER_TYPES } from '../../../../constants'
import {
  ClientBooking,
  MerchantBooking,
} from '../../../../lib/api/availabilities/types'
import Modal from '../../../components/modals/Modal'
import AddAvailabilityDialogue from '../../../containers/dialogues/AddAvailabilityDialogue'

export enum DIALOGUE_TITLE {
  CLIENT = 'Add Client availability',
  MERCHANT = 'Add Pro availability',
}

export interface IAddClientAvailabilityDialogueModal {
  className?: string
  bookings?: Array<ClientBooking | MerchantBooking>
  isShowing?: boolean
  jobId: number
  onCloseDialogue?: () => void
  onSelectAvailability?: (startDate: Date, endDate: Date) => void
  userId: number
  userType?: USER_TYPES
}

export default ({
  className = '',
  isShowing = false,
  jobId,
  onCloseDialogue = noop,
  userId,
  userType = USER_TYPES.CLIENT,
}: IAddClientAvailabilityDialogueModal) => {
  const dialogueTitle =
    userType === USER_TYPES.CLIENT
      ? DIALOGUE_TITLE.CLIENT
      : DIALOGUE_TITLE.MERCHANT
  return (
    <Modal isShowing={isShowing}>
      <AddAvailabilityDialogue
        className={className}
        currentDate={new Date()}
        dialogueTitle={dialogueTitle}
        jobId={jobId}
        onCloseDialogue={onCloseDialogue}
        userId={userId}
        userType={userType}
      />
    </Modal>
  )
}
