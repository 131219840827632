import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { ReactComponent as _IconComponent } from './assets/logo.svg'

export const IconComponent = styled(_IconComponent)`
  height: 100%;
  width: 100%;
`

export const SetterLogoIcon = styled(Icon)`
  height: 40px;
  width: 140px;
`

export interface ISetterLogoIcon {
  className?: string
}
export default ({ className }: ISetterLogoIcon) => (
  <SetterLogoIcon className={className}>
    <IconComponent />
  </SetterLogoIcon>
)
