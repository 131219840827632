import React from 'react'
import Icon from '../Icon'
import { ReactComponent as IconComponent } from './assets/right-arrow.svg'

export const RightArrowIcon = Icon

export interface IRightArrowIcon {
  className?: string
}
export default ({ className }: IRightArrowIcon) => (
  <RightArrowIcon className={className}>
    <IconComponent />
  </RightArrowIcon>
)
