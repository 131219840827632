import { chain, head, last, noop } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADD_USER_AVAILABILITY_DIALOGUE_TITLE,
  USER_TYPES,
} from '../../../../constants'
import actions from '../../../../lib/redux/availabilities/actions'
import {
  getClientAvailabilitiesByClientIdSelector,
  getClientBookingsByJobIdSelector,
  getMerchantAvailabilitiesByMerchantIdSelector,
  getMerchantBookingsByJobIdSelector,
} from '../../../../lib/redux/availabilities/selectors'
import AddAvailabilityDialogue from '../../../composites/dialogues/AddAvailabilityDialogue'

export interface IAddAvailabilityDialogue {
  className: string
  currentDate?: Date
  dialogueTitle?: string
  jobId: number
  onCloseDialogue?: () => void
  userId: number
  userType?: USER_TYPES
}

export type CalendarStartEndDate = {
  startDate: Date | undefined
  endDate: Date | undefined
}

export const getCalendarStartEndDate = (
  calendarDates: Date[]
): CalendarStartEndDate =>
  chain(calendarDates)
    .thru(dates => [head(dates), last(dates)])
    .thru(([startDate, endDate]) => [
      moment(startDate)
        .hour(0)
        .minute(0)
        .second(0)
        .subtract(1, 'second')
        .toDate(),
      moment(endDate)
        .hour(0)
        .minute(0)
        .second(0)
        .toDate(),
    ])
    .thru((dates: Date[]): {
      startDate: Date | undefined
      endDate: Date | undefined
    } => ({
      startDate: head(dates),
      endDate: last(dates),
    }))
    .value()

export default ({
  className = '',
  currentDate = new Date(),
  dialogueTitle = ADD_USER_AVAILABILITY_DIALOGUE_TITLE,
  jobId,
  onCloseDialogue = noop,
  userId,
  userType = USER_TYPES.CLIENT,
}: IAddAvailabilityDialogue) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (userType === USER_TYPES.CLIENT) {
      dispatch(actions.getClientBookingsByJobId(jobId))
    }

    if (userType === USER_TYPES.MERCHANT) {
      dispatch(actions.getMerchantBookingsByJobId(jobId))
    }
  }, [dispatch, jobId, userType])

  const [selectedDate, setSelectedDate] = useState((): Date => currentDate)

  const getClientAvailabilitiesByClientId = useSelector(
    getClientAvailabilitiesByClientIdSelector
  )
  const getMerchantAvailabilitiesByMerchantId = useSelector(
    getMerchantAvailabilitiesByMerchantIdSelector
  )

  const getClientBookingsByJobId = useSelector(getClientBookingsByJobIdSelector)
  const getMerchantBookingsByJobId = useSelector(
    getMerchantBookingsByJobIdSelector
  )

  const { startDate: calendarStartDate, endDate: calendarEndDate } =
    userType === USER_TYPES.CLIENT
      ? chain(jobId)
          .thru(getClientBookingsByJobId)
          .map(({ startDate: date }): Date => new Date(date))
          .thru(getCalendarStartEndDate)
          .value()
      : chain(jobId)
          .thru(getMerchantBookingsByJobId)
          .map(({ startDate: date }): Date => new Date(date))
          .thru(getCalendarStartEndDate)
          .value()

  const bookings =
    userType === USER_TYPES.CLIENT
      ? getClientBookingsByJobId(jobId).filter(booking =>
          moment(booking.startDate).isSame(moment(selectedDate), 'day')
        )
      : getMerchantBookingsByJobId(jobId).filter(booking =>
          moment(booking.startDate).isSame(moment(selectedDate), 'day')
        )

  const selectedClientAvailabilities = getClientAvailabilitiesByClientId(
    userId,
    jobId
  )
  const selectedMerchantAvailabilities = getMerchantAvailabilitiesByMerchantId(
    userId,
    jobId
  )

  const onAddAvailability = (startDate: Date, endDate: Date): void => {
    if (userType === USER_TYPES.CLIENT) {
      // FIXME - need a better way
      dispatch(
        actions.addClientAvailability(
          userId,
          jobId,
          new Date(startDate),
          new Date(endDate)
        )
      )
    }
    if (userType === USER_TYPES.MERCHANT) {
      dispatch(
        actions.addMerchantAvailability(
          userId,
          jobId,
          new Date(startDate),
          new Date(endDate)
        )
      )
    }
  }

  const onDeleteAvailability = (optionIndex: number): void => {
    if (userType === USER_TYPES.CLIENT) {
      dispatch(
        actions.deleteClientAvailability(
          selectedClientAvailabilities[optionIndex]
        )
      )
    }

    if (userType === USER_TYPES.MERCHANT) {
      dispatch(
        actions.deleteMerchantAvailability(
          selectedMerchantAvailabilities[optionIndex]
        )
      )
    }
  }

  return (
    <AddAvailabilityDialogue
      className={className}
      bookings={bookings}
      todayDate={currentDate}
      currentDate={selectedDate}
      dialogueTitle={dialogueTitle}
      onCloseDialogue={onCloseDialogue}
      onConfirmDialogue={onCloseDialogue}
      onDeleteAvailability={onDeleteAvailability}
      onAddAvailability={onAddAvailability}
      onSelectDate={setSelectedDate}
      selectedAvailabilities={
        userType === USER_TYPES.MERCHANT
          ? selectedMerchantAvailabilities
          : selectedClientAvailabilities
      }
      startDate={calendarStartDate}
      endDate={calendarEndDate}
    />
  )
}
