import React from 'react'
import styled from 'styled-components'
import PrimaryButton from '../../../components/buttons/PrimaryButton'

export interface IFooter {
  onConfirm?: () => void
}

const Footer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0 120px;
  width: 100%;
`

export const ConfirmButton = PrimaryButton

export default ({ onConfirm }: IFooter) => (
  <Footer>
    <ConfirmButton onClick={onConfirm} isSelected={true}>
      Confirm
    </ConfirmButton>
  </Footer>
)
