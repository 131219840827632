import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import LeftArrowCircleIcon from '../../icons/LeftArrowCircleIcon'

export interface ILeftArrowButton {
  className?: string
  onClick?: () => void
}

export const LeftArrowButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

export default ({ className = '', onClick = noop }: ILeftArrowButton) => (
  <LeftArrowButton className={className} onClick={onClick}>
    <LeftArrowCircleIcon />
  </LeftArrowButton>
)
