import React from 'react'
import Icon from '../Icon'
import { ReactComponent as FilledIconComponent } from './assets/flag-filled.svg'
import { ReactComponent as UnfilledIconComponent } from './assets/flag-unfilled.svg'

export const FlagIcon = Icon

export interface IFlagIcon {
  className?: string
  filled?: boolean
}
export default ({ className, filled = false }: IFlagIcon) => (
  <FlagIcon className={className}>
    {filled ? <FilledIconComponent /> : <UnfilledIconComponent />}
  </FlagIcon>
)
