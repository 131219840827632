import axios from 'axios'

export enum REQUEST_METHOD_TYPE {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export interface IRequest<H extends {}, T, R> {
  data?: T
  headers?: H
  method: REQUEST_METHOD_TYPE
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R
  onErrorRequestHandler: (statusCode: number, message: string) => R
  url: string
}

export const createRequest = async <H extends {}, T, R>({
  data,
  headers,
  method,
  onSuccessRequestHandler,
  onErrorRequestHandler,
  url,
}: IRequest<H, T, R>): Promise<R> => {
  return axios
    .request<R>({
      url,
      method,
      headers,
      data,
    })
    .then(response => onSuccessRequestHandler(response.status, response.data))
    .catch(err => {
      if (err.response) {
        return onErrorRequestHandler(
          err.response.status,
          err.response.data as string
        )
      }

      return onErrorRequestHandler(200, 'Parsing Validation error')
    })
}

export default {
  REQUEST_METHOD_TYPE,
  createRequest,
}
