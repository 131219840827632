import React from 'react'
import styled from 'styled-components'

export interface IFooter {
  className?: string
  children?: React.ReactNode | null
}

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  line-height: 0px;
  margin-bottom: 40px;
`

export default ({ className = '', children = null }: IFooter) => (
  <Footer className={className}>{children}</Footer>
)
