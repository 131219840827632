import React from 'react'
import styled from 'styled-components'
import { USER_AVAILABILITIES_NOT_SUBMITTED_LABEL } from '../../../../constants'
import Card from '../../../components/cards/Card'

export interface INoAvailabilitiesCard {
  className?: string
  children?: React.ReactNode[] | React.ReactNode | null
}

export const NoAvailabilitiesCard = styled(Card)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
`

export default ({
  children = USER_AVAILABILITIES_NOT_SUBMITTED_LABEL,
}: INoAvailabilitiesCard) => (
  <NoAvailabilitiesCard>{children}</NoAvailabilitiesCard>
)
