import React from 'react'
import Icon from '../Icon'
import { ReactComponent as EmojiComponent } from './assets/frown-face-emoji.svg'

export const FrowningFaceIcon = Icon

export interface IFrowningFaceIcon {
  className?: string
}
export default ({ className = '' }: IFrowningFaceIcon) => (
  <FrowningFaceIcon className={className}>
    <EmojiComponent />
  </FrowningFaceIcon>
)
