declare global {
  interface Window {
    analytics: Analytics
  }

  interface Analytics {
    initialized: boolean
    load: (writeKey: string) => void
    identify: (userId: string, user: AnalyticsUser) => void
    track: (eventName: string, eventData: AnalyticsEventData) => void
  }
}

export enum AnalyticsEvent {
  ClientAvailabilityAdded = 'ClientAvailabilityAdded',
  MerchantAvailabilityAdded = 'MerchantAvailabilityAdded',
  JobScheduled = 'JobScheduled',
  AppLoaded = 'AppLoaded',
}

export interface AnalyticsEventData {
  merchantId: number
  clientId: number
  jobId: number
  startDate?: string | null
  endDate?: string | null
}

export interface AnalyticsUser {
  first_name: string
  last_name: string
  email: string
  cellphone?: string
}
