import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import StarIcon from '../../icons/StarIcon'

export interface IStarToggleButton {
  className?: string
  isSelected?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const StarToggleButton = styled.button<IStarToggleButton>`
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  outline: none;
`

export default ({
  className = '',
  isSelected = false,
  onClick = noop,
}: IStarToggleButton) => (
  <StarToggleButton
    data-testid="star-toggle-button"
    className={className}
    isSelected={isSelected}
    onClick={onClick}
  >
    <StarIcon filled={isSelected} />
  </StarToggleButton>
)
