import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import CheckBoxButton from '../../../components/buttons/CheckBoxButton'
import Card from '../../../components/cards/Card'

export interface INotificationSettings {
  className?: string
  title?: string
  options?: string[]
  onSelectOption?: (index: number) => void
  selectedIndices?: number[]
}

export const NotificationSettings = styled(Card)`
  border-radius: 4px;
  background-color: #ffffff;
  color: #344e63;
  display: flex;
  flex-direction: column;

  h1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    font-size: 16px;
    font-weight: normal;
    margin: 0px;
    padding: 10px 15px;
  }

  ul {
    margin: 11px;
    list-style-type: none;
    margin: 20px 15px;
    padding: 0;

    & li {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & label {
      margin-left: 18px;
    }
  }
`

export default ({
  className = '',
  title = 'Notification Settings',
  options = [],
  onSelectOption = noop,
  selectedIndices = [],
}: INotificationSettings) => {
  const settings = options.map((label: string, index: number) => (
    <li key={`checkbox-button-${index}`}>
      <CheckBoxButton
        onClick={() => onSelectOption(index)}
        isSelected={selectedIndices.includes(index)}
      />
      <label>{label}</label>
    </li>
  ))

  return (
    <NotificationSettings className={className}>
      <h1>{title}</h1>
      <ul>{settings}</ul>
    </NotificationSettings>
  )
}
