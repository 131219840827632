import React from 'react'
import { MATCH_STATUS_TYPE } from '../../../../constants'
import NoMatchIcon from '../../../components/icons/FrowningFaceIcon'
import MatchIcon from '../../../components/icons/HappyFaceIcon'
import MissMatchIcon from '../../../components/icons/NeutralFaceIcon'

export interface IMatchStatusIcon {
  className?: string
  type?: MATCH_STATUS_TYPE | null
}

export default ({ className = '', type = null }: IMatchStatusIcon) => {
  if (type === MATCH_STATUS_TYPE.MATCH) {
    return <MatchIcon className={className} />
  }

  if (type === MATCH_STATUS_TYPE.MISS_MATCH) {
    return <MissMatchIcon className={className} />
  }

  if (type === MATCH_STATUS_TYPE.NO_MATCH) {
    return <NoMatchIcon className={className} />
  }

  return <NoMatchIcon className={className} />
}
