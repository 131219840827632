import { noop } from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SCHEDULE_JOB_STEPS } from '../../../../constants'
import jobsActions from '../../../../lib/redux/jobs/actions'
import ScheduleJobDialogue from '../../../composites/dialogues/ScheduleJobDialogue'

export interface IScheduleJobDialogue {
  className?: string
  jobId: number
  onCloseDialogue?: () => void
}

export default ({
  className = '',
  jobId,
  onCloseDialogue = noop,
}: IScheduleJobDialogue) => {
  const dispatch = useDispatch()
  const [scheduleJobStep, setScheduleJobStep] = useState(
    (): SCHEDULE_JOB_STEPS => SCHEDULE_JOB_STEPS.STEP_1
  )

  const onNextStep = () => {
    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_1) {
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_2)
    }

    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_2) {
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_3)
    }

    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_3) {
      dispatch(jobsActions.getJobsByFilter())
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_1)
    }

    return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_1)
  }

  const onPreviousStep = () => {
    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_1) {
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_1)
    }

    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_2) {
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_1)
    }

    if (scheduleJobStep === SCHEDULE_JOB_STEPS.STEP_3) {
      return setScheduleJobStep(SCHEDULE_JOB_STEPS.STEP_2)
    }
  }

  return (
    <ScheduleJobDialogue
      className={className}
      jobId={jobId}
      onCloseDialogue={onCloseDialogue}
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
      scheduleJobStep={scheduleJobStep}
    />
  )
}
