import { noop } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { getJobByJobIdSelector } from '../../../../lib/redux/jobs/selectors'
import { formatDateIntervalString } from '../../../../utils/format-strings'
import ScheduleJobStep3Card from '../../../composites/cards/ScheduleJobStep3Card'

export interface IScheduleJobStep3Card {
  className?: string
  jobId: number
  onNextStep?: () => void
}

export default ({
  className = '',
  jobId,
  onNextStep = noop,
}: IScheduleJobStep3Card) => {
  const getJobByJobId = useSelector(getJobByJobIdSelector)
  const job = getJobByJobId(jobId)
  const invitationDate =
    job && job.startDate && job.endDate
      ? formatDateIntervalString(new Date(job.startDate), new Date(job.endDate))
      : ''

  return (
    <ScheduleJobStep3Card
      className={className}
      invitationDate={invitationDate}
      onNextStep={onNextStep}
    />
  )
}
