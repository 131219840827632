import { Interval } from 'luxon'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USER_TYPES } from '../../../../constants'
import {
  ClientAvailability,
  MerchantAvailability,
} from '../../../../lib/api/availabilities/types'
import {
  deleteClientAvailability,
  deleteMerchantAvailability,
} from '../../../../lib/redux/availabilities/actions'
import {
  getClientAvailabilitiesByClientIdSelector,
  getClientMatchStatusTypeByJobIdSelector,
  getMatchingAvailabilitiesByJobIdSelector,
  getMerchantAvailabilitiesByMerchantIdSelector,
  getMerchantMatchStatusTypeByJobIdSelector,
} from '../../../../lib/redux/availabilities/selectors'
import { formatDateIntervalString } from '../../../../utils/format-strings'
import AvailabilityCard from '../../../composites/cards/AvailabilityCard'
import AddAvailabilityDialogueModal from '../../../composites/modals/AddAvailabilityDialogueModal'

export interface IAvailabilityCard {
  className?: string
  headerChildren?: React.ReactNode[] | React.ReactNode | null
  subHeaderChildren?: React.ReactNode[] | React.ReactNode | null
  jobId: number
  userId: number
  userType?: USER_TYPES
}

export default ({
  className = '',
  headerChildren = null,
  subHeaderChildren = null,
  jobId,
  userId,
  userType = USER_TYPES.CLIENT,
}: IAvailabilityCard) => {
  const dispatch = useDispatch()
  const [
    isAddClientAvailabilityDialogueModalShowing,
    setAddClientAvailabilityDialogueModalShowing,
  ] = useState((): boolean => false)

  const [
    isAddMerchantAvailabilityDialogueModalShowing,
    setAddMerchantAvailabilityDialogueModalShowing,
  ] = useState((): boolean => false)

  const getClientAvailabilitiesByClientId = useSelector(
    getClientAvailabilitiesByClientIdSelector
  )
  const getMerchantAvailabilitiesByMerchantId = useSelector(
    getMerchantAvailabilitiesByMerchantIdSelector
  )

  const getMatchingAvailabilitiesByJobId = useSelector(
    getMatchingAvailabilitiesByJobIdSelector
  )

  const geClientMatchStatusTypeByJobId = useSelector(
    getClientMatchStatusTypeByJobIdSelector
  )

  const getMerchantMatchStatusTypeByJobId = useSelector(
    getMerchantMatchStatusTypeByJobIdSelector
  )

  const clientAvailabilities = getClientAvailabilitiesByClientId(userId, jobId)
  const merchantAvailabilities = getMerchantAvailabilitiesByMerchantId(
    userId,
    jobId
  )
  const dateIntervalString =
    userType === USER_TYPES.CLIENT
      ? clientAvailabilities.map(availability =>
          // FIXME - need a better way
          formatDateIntervalString(
            new Date(availability.startDate),
            new Date(availability.endDate)
          )
        )
      : merchantAvailabilities.map(availability =>
          // FIXME - need a better way
          formatDateIntervalString(
            new Date(availability.startDate),
            new Date(availability.endDate)
          )
        )

  const matchingAvailabilities = getMatchingAvailabilitiesByJobId(jobId)

  const matchStatusType =
    userType === USER_TYPES.CLIENT
      ? geClientMatchStatusTypeByJobId(jobId)
      : getMerchantMatchStatusTypeByJobId(jobId)

  const isMaxSelectionReached =
    (userType === USER_TYPES.CLIENT && clientAvailabilities.length === 3) ||
    (userType === USER_TYPES.MERCHANT && merchantAvailabilities.length === 3)

  const selectedIndices =
    userType === USER_TYPES.CLIENT
      ? clientAvailabilities.reduce(
          (indices: number[], availability, availabilityIndex) => {
            return matchingAvailabilities.clients.reduce(
              (_indices: number[], _availability: ClientAvailability) => {
                const availabilityInterval = Interval.fromDateTimes(
                  // FIXME - need a better way
                  new Date(availability.startDate),
                  new Date(availability.endDate)
                )

                // FIXME - need a better way
                const _availabilityInterval = Interval.fromDateTimes(
                  new Date(_availability.startDate),
                  new Date(_availability.endDate)
                )

                if (availabilityInterval.equals(_availabilityInterval)) {
                  return _indices.concat(availabilityIndex)
                }
                return _indices
              },
              indices
            )
          },
          []
        )
      : merchantAvailabilities.reduce(
          (indices: number[], availability, availabilityIndex) => {
            return matchingAvailabilities.merchants.reduce(
              (_indices: number[], _availability: MerchantAvailability) => {
                // FIXME - need a better way
                const availabilityInterval = Interval.fromDateTimes(
                  new Date(availability.startDate),
                  new Date(availability.endDate)
                )

                // FIXME - need a better way
                const _availabilityInterval = Interval.fromDateTimes(
                  new Date(_availability.startDate),
                  new Date(_availability.endDate)
                )

                if (availabilityInterval.equals(_availabilityInterval)) {
                  return _indices.concat(availabilityIndex)
                }
                return _indices
              },
              indices
            )
          },
          []
        )

  const onAddAvailability = () => {
    if (isMaxSelectionReached) {
      return
    }

    if (userType === USER_TYPES.CLIENT) {
      setAddClientAvailabilityDialogueModalShowing(true)
      setAddMerchantAvailabilityDialogueModalShowing(false)
    }
    if (userType === USER_TYPES.MERCHANT) {
      setAddClientAvailabilityDialogueModalShowing(false)
      setAddMerchantAvailabilityDialogueModalShowing(true)
    }
  }

  const onDeleteAvailability = (optionIndex: number) => {
    if (userType === USER_TYPES.CLIENT) {
      dispatch(deleteClientAvailability(clientAvailabilities[optionIndex]))
    }
    if (userType === USER_TYPES.MERCHANT) {
      dispatch(deleteMerchantAvailability(merchantAvailabilities[optionIndex]))
    }
  }

  return (
    <>
      <AddAvailabilityDialogueModal
        jobId={jobId}
        isShowing={isAddClientAvailabilityDialogueModalShowing}
        onCloseDialogue={() =>
          setAddClientAvailabilityDialogueModalShowing(false)
        }
        userId={userId}
        userType={USER_TYPES.CLIENT}
      />
      <AddAvailabilityDialogueModal
        jobId={jobId}
        isShowing={isAddMerchantAvailabilityDialogueModalShowing}
        onCloseDialogue={() =>
          setAddMerchantAvailabilityDialogueModalShowing(false)
        }
        userId={userId}
        userType={USER_TYPES.MERCHANT}
      />
      <AvailabilityCard
        className={className}
        headerChildren={headerChildren}
        jobMatchStatusType={matchStatusType}
        options={dateIntervalString}
        onAddAvailability={onAddAvailability}
        onDeleteAvailability={onDeleteAvailability}
        selectedIndices={selectedIndices}
        subHeaderChildren={subHeaderChildren}
      />
    </>
  )
}
