import { track } from 'lib/analytics'
import { AnalyticsEvent, AnalyticsEventData } from 'lib/analytics/types'
import api from '../../api/availabilities'
import {
  ClientAvailability,
  ClientBooking,
  MerchantAvailability,
  MerchantBooking,
} from '../../api/availabilities/types'
import { Dispatch } from '../index'
import jobsActions from '../jobs/actions'
import profilesActions from '../profiles/actions'

export enum ACTION_TYPES {
  ADD_MERCHANT_AVAILABILITY_INIT = 'ADD_MERCHANT_AVAILABILITY_INIT',
  ADD_MERCHANT_AVAILABILITY_SUCCESS = 'ADD_MERCHANT_AVAILABILITY_SUCCESS',
  ADD_MERCHANT_AVAILABILITY_FAILURE = 'ADD_MERCHANT_AVAILABILITY_FAILURE',

  ADD_CLIENT_AVAILABILITY_INIT = 'ADD_CLIENT_AVAILABILITY_INIT',
  ADD_CLIENT_AVAILABILITY_SUCCESS = 'ADD_CLIENT_AVAILABILITY_SUCCESS',
  ADD_CLIENT_AVAILABILITY_FAILURE = 'ADD_CLIENT_AVAILABILITY_FAILURE',

  DELETE_CLIENT_AVAILABILITY_INIT = 'DELETE_CLIENT_AVAILABILITY_INIT',
  DELETE_CLIENT_AVAILABILITY_SUCCESS = 'DELETE_CLIENT_AVAILABILITY_SUCCESS',
  DELETE_CLIENT_AVAILABILITY_FAILURE = 'DELETE_CLIENT_AVAILABILITY_FAILURE',

  DELETE_MERCHANT_AVAILABILITY_INIT = 'DELETE_MERCHANT_AVAILABILITY_INIT',
  DELETE_MERCHANT_AVAILABILITY_SUCCESS = 'DELETE_MERCHANT_AVAILABILITY_SUCCESS',
  DELETE_MERCHANT_AVAILABILITY_FAILURE = 'DELETE_MERCHANT_AVAILABILITY_FAILURE',

  GET_CLIENT_BOOKINGS_INIT = 'GET_CLIENT_BOOKINGS_INIT',
  GET_CLIENT_BOOKINGS_SUCCESS = 'GET_CLIENT_BOOKINGS_SUCCESS',
  GET_CLIENT_BOOKINGS_FAILURE = 'GET_CLIENT_BOOKINGS_FAILURE',

  GET_MERCHANT_BOOKINGS_INIT = 'GET_MERCHANT_BOOKINGS_INIT',
  GET_MERCHANT_BOOKINGS_SUCCESS = 'GET_MERCHANT_BOOKINGS_SUCCESS',
  GET_MERCHANT_BOOKINGS_FAILURE = 'GET_MERCHANT_BOOKINGS_FAILURE',

  UPDATE_CLIENT_BOOKINGS = 'UPDATE_CLIENT_BOOKINGS',
  UPDATE_MERCHANT_BOOKINGS = 'UPDATE_MERCHANT_BOOKINGS',

  REMOVE_CLIENT_AVAILABILITY = 'REMOVE_CLIENT_AVAILABILITY',
  REMOVE_MERCHANT_AVAILABILITY = 'REMOVE_MERCHANT_AVAILABILITY',

  UPDATE_CLIENT_AVAILABILITIES = 'UPDATE_CLIENT_AVAILABILITIES',
  UPDATE_MERCHANT_AVAILABILITIES = 'UPDATE_MERCHANT_AVAILABILITIES',
}

export type Action =
  | { type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_INIT }
  | { type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_SUCCESS }
  | {
      type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_INIT }
  | { type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_SUCCESS }
  | {
      type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_INIT }
  | { type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_SUCCESS }
  | {
      type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_INIT }
  | { type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_SUCCESS }
  | {
      type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.GET_CLIENT_BOOKINGS_INIT }
  | { type: ACTION_TYPES.GET_CLIENT_BOOKINGS_SUCCESS }
  | {
      type: ACTION_TYPES.GET_CLIENT_BOOKINGS_FAILURE
      data: { error: Error }
    }
  | { type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_INIT }
  | { type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_SUCCESS }
  | {
      type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_FAILURE
      data: { error: Error }
    }
  | {
      type: ACTION_TYPES.UPDATE_CLIENT_BOOKINGS
      data: { clientBookings: ClientBooking[] }
    }
  | {
      type: ACTION_TYPES.UPDATE_MERCHANT_BOOKINGS
      data: { merchantBookings: MerchantBooking[] }
    }
  | {
      type: ACTION_TYPES.REMOVE_CLIENT_AVAILABILITY
      data: { clientAvailability: ClientAvailability }
    }
  | {
      type: ACTION_TYPES.REMOVE_MERCHANT_AVAILABILITY
      data: { merchantAvailability: MerchantAvailability }
    }
  | {
      type: ACTION_TYPES.UPDATE_CLIENT_AVAILABILITIES
      data: { clientAvailabilities: ClientAvailability[] }
    }
  | {
      type: ACTION_TYPES.UPDATE_MERCHANT_AVAILABILITIES
      data: { merchantAvailabilities: MerchantAvailability[] }
    }

export const addClientAvailability = (
  clientId: number,
  jobId: number,
  startDate: Date,
  endDate: Date
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_INIT })
      const {
        job,
        clientAvailabilities,
        clientProfile,
        merchantProfile,
      } = await api.postClientAvailabilityByJobId(
        clientId,
        startDate,
        endDate,
        jobId
      )
      const trackEventData: AnalyticsEventData = {
        clientId: job.clientId,
        jobId: job.id,
        merchantId: job.merchantId,
        startDate: job.startDate,
        endDate: job.endDate,
      }
      track(AnalyticsEvent.ClientAvailabilityAdded, trackEventData)
      dispatch(jobsActions.updateJob(job))
      dispatch(profilesActions.updateClientProfile(clientProfile))
      dispatch(profilesActions.updateMerchantProfile(merchantProfile))
      dispatch(updateClientAvailabilities(clientAvailabilities))
      dispatch({ type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_SUCCESS })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.ADD_CLIENT_AVAILABILITY_FAILURE,
        data: { error },
      })
    }
  }
}

export const addMerchantAvailability = (
  merchantId: number,
  jobId: number,
  startDate: Date,
  endDate: Date
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_INIT })
      const {
        job,
        merchantAvailabilities,
        clientProfile,
        merchantProfile,
      } = await api.postMerchantAvailabilityByJobId(
        merchantId,
        startDate,
        endDate,
        jobId
      )
      const trackEventData: AnalyticsEventData = {
        clientId: job.clientId,
        jobId: job.id,
        merchantId: job.merchantId,
        startDate: job.startDate,
        endDate: job.endDate,
      }
      track(AnalyticsEvent.MerchantAvailabilityAdded, trackEventData)
      dispatch(jobsActions.updateJob(job))
      dispatch(profilesActions.updateClientProfile(clientProfile))
      dispatch(profilesActions.updateMerchantProfile(merchantProfile))
      dispatch(updateMerchantAvailabilities(merchantAvailabilities))
      dispatch({ type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_SUCCESS })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.ADD_MERCHANT_AVAILABILITY_FAILURE,
        data: { error },
      })
    }
  }
}

export const deleteClientAvailability = (
  clientAvailability: ClientAvailability
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_INIT })
      const {
        job,
        clientAvailabilities,
        clientProfile,
        merchantProfile,
      } = await api.deleteClientAvailabilityByAvailabilityId(
        clientAvailability.availabilityId
      )

      dispatch(jobsActions.updateJob(job))
      dispatch(removeClientAvailability(clientAvailability))
      dispatch(updateClientAvailabilities(clientAvailabilities))
      dispatch(profilesActions.updateClientProfile(clientProfile))
      dispatch(profilesActions.updateMerchantProfile(merchantProfile))
      dispatch({ type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_SUCCESS })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.DELETE_CLIENT_AVAILABILITY_FAILURE,
        data: { error },
      })
    }
  }
}

export const deleteMerchantAvailability = (
  merchantAvailability: MerchantAvailability
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_INIT })
    const {
      job,
      clientProfile,
      merchantAvailabilities,
      merchantProfile,
    } = await api.deleteMerchantAvailabilityByAvailabilityId(
      merchantAvailability.availabilityId
    )

    dispatch(jobsActions.updateJob(job))
    dispatch(removeMerchantAvailability(merchantAvailability))
    dispatch(updateMerchantAvailabilities(merchantAvailabilities))
    dispatch(profilesActions.updateClientProfile(clientProfile))
    dispatch(profilesActions.updateMerchantProfile(merchantProfile))
    dispatch({ type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.DELETE_MERCHANT_AVAILABILITY_FAILURE,
      data: { error },
    })
  }
}

export const updateClientBookings = (
  clientBookings: ClientBooking[]
): Action => ({
  type: ACTION_TYPES.UPDATE_CLIENT_BOOKINGS,
  data: { clientBookings },
})

export const updateMerchantBookings = (
  merchantBookings: MerchantBooking[]
): Action => ({
  type: ACTION_TYPES.UPDATE_MERCHANT_BOOKINGS,
  data: { merchantBookings },
})

export const getClientBookingsByJobId = (jobId: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.GET_CLIENT_BOOKINGS_INIT })
    const {
      clientBookings,
      clientAvailabilities,
      clientProfile,
      job,
    } = await api.getClientBookingsByJobId(jobId)
    dispatch(updateClientAvailabilities(clientAvailabilities))
    dispatch(profilesActions.updateClientProfile(clientProfile))
    dispatch(jobsActions.updateJob(job))
    dispatch(updateClientBookings(clientBookings))
    dispatch({ type: ACTION_TYPES.GET_CLIENT_BOOKINGS_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_CLIENT_BOOKINGS_FAILURE,
      data: { error },
    })
  }
}

export const getMerchantBookingsByJobId = (jobId: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_INIT })
    const {
      merchantBookings,
      merchantAvailabilities,
      merchantProfile,
      job,
    } = await api.getMerchantBookingsByJobId(jobId)

    dispatch(updateMerchantAvailabilities(merchantAvailabilities))
    dispatch(profilesActions.updateMerchantProfile(merchantProfile))
    dispatch(jobsActions.updateJob(job))
    dispatch(updateMerchantBookings(merchantBookings))
    dispatch({ type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_SUCCESS })
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_MERCHANT_BOOKINGS_FAILURE,
      data: { error },
    })
  }
}

export const removeClientAvailability = (
  clientAvailability: ClientAvailability
): Action => ({
  type: ACTION_TYPES.REMOVE_CLIENT_AVAILABILITY,
  data: { clientAvailability },
})

export const removeMerchantAvailability = (
  merchantAvailability: MerchantAvailability
): Action => ({
  type: ACTION_TYPES.REMOVE_MERCHANT_AVAILABILITY,
  data: { merchantAvailability },
})

export const updateClientAvailabilities = (
  clientAvailabilities: ClientAvailability[]
): Action => ({
  type: ACTION_TYPES.UPDATE_CLIENT_AVAILABILITIES,
  data: { clientAvailabilities },
})

export const updateMerchantAvailabilities = (
  merchantAvailabilities: MerchantAvailability[]
): Action => ({
  type: ACTION_TYPES.UPDATE_MERCHANT_AVAILABILITIES,
  data: { merchantAvailabilities },
})

export default {
  addClientAvailability,
  addMerchantAvailability,
  deleteClientAvailability,
  deleteMerchantAvailability,
  getClientBookingsByJobId,
  getMerchantBookingsByJobId,
  updateClientAvailabilities,
  updateMerchantAvailabilities,
}
