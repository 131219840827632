import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Table, {
  TableColumn as _TableColumn,
  TableRow as _TableRow,
} from '../../tables/Table'
import Footer from './Footer'
import Header from './Header'

export interface IDialogue {
  className?: string
  headerChildren?: React.ReactNode[] | React.ReactNode | null
  children?: React.ReactNode[] | React.ReactNode | null
  footerChildren?: React.ReactNode[] | React.ReactNode | null
  onCloseDialogue?: () => void
}

export const Dialogue = styled(Table)`
  background-color: #f0f2f5;
  width: 700px;
`

export const Row = styled(_TableRow)`
  justify-content: center;
`

export const Column = styled(_TableColumn)`
  align-items: center;
  flex: 0 0 auto;
  margin: 30px;
`

export default ({
  className = '',
  headerChildren = null,
  children = null,
  footerChildren = null,
  onCloseDialogue = noop,
}: IDialogue) => (
  <Dialogue
    className={className}
    headerChildren={<Header onClose={onCloseDialogue}>{headerChildren}</Header>}
    footerChildren={<Footer>{footerChildren}</Footer>}
  >
    <Row>
      <Column>{children}</Column>
    </Row>
  </Dialogue>
)
