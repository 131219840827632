import { JobsFilter } from 'lib/api/jobs/types'
import { get } from 'lodash'
import { RemoteData, cata } from 'remote-data-ts'
import { JOB_FILTER_END_DATE, JOB_FILTER_START_DATE } from '../../../constants'
import { FILTER_RESOURCES } from '../../api/search/types'
import { ACTION_TYPES, Action } from './actions'

export type ReduxState = {
  filter: JobsFilter
}

export type RemoteDataState = RemoteData<ReduxState, Error>

export const initialReduxState: ReduxState = {
  filter: {
    startDate: JOB_FILTER_START_DATE,
    endDate: JOB_FILTER_END_DATE,
    clientIds: [],
    merchantIds: [],
    orderIds: [],
    jobIds: [],
  },
}

export const initialRemoteDataState: RemoteDataState = RemoteData.notAsked()

export const resourceIds = {
  [FILTER_RESOURCES.Client]: 'clientIds',
  [FILTER_RESOURCES.Merchant]: 'merchantIds',
  [FILTER_RESOURCES.Order]: 'orderIds',
  [FILTER_RESOURCES.Job]: 'jobIds',
}

export const reduxReducer = (
  state: ReduxState = initialReduxState,
  action: Action
): ReduxState => {
  if (action.type === ACTION_TYPES.RESET_SEARCH_FILTER) {
    return {
      ...state,
      filter: initialReduxState.filter,
    }
  }
  if (action.type === ACTION_TYPES.REMOVE_SEARCH_FILTER) {
    const searchResource: FILTER_RESOURCES = get(
      FILTER_RESOURCES,
      action.data.result.resource
    )
    const searchResourceId = action.data.result.id
    const resourceIdList: number[] = get(
      state.filter,
      resourceIds[searchResource]
    )

    return {
      ...state,
      filter: {
        ...state.filter,
        [resourceIds[searchResource]]: resourceIdList.filter(
          id => id !== searchResourceId
        ),
      },
    }
  }
  if (action.type === ACTION_TYPES.UPDATE_SEARCH_FILTER) {
    const searchResource: FILTER_RESOURCES = get(
      FILTER_RESOURCES,
      action.data.result.resource
    )
    const searchResourceId = action.data.result.id
    const resourceIdList: number[] = get(
      state.filter,
      resourceIds[searchResource]
    )
    return {
      ...state,
      filter: {
        ...state.filter,
        [resourceIds[searchResource]]: [searchResourceId].concat(
          resourceIdList
        ),
      },
    }
  }

  return state
}

export const remoteDataReducer = (
  state: RemoteDataState = initialRemoteDataState,
  action: Action
): RemoteDataState => {
  return cata<ReduxState, Error, RemoteDataState>({
    notAsked: () => {
      if (
        action.type === ACTION_TYPES.UPDATE_SEARCH_FILTER_INIT ||
        action.type === ACTION_TYPES.REMOVE_SEARCH_FILTER_INIT ||
        action.type === ACTION_TYPES.RESET_SEARCH_FILTER_INIT
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }
      return state
    },
    loading: () => {
      return state
    },
    success: (reduxState: ReduxState) => {
      return RemoteData.success(reduxReducer(reduxState, action))
    },
    failure: (error: Error) => {
      return state
    },
  })(state)
}

export default remoteDataReducer
