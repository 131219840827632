import { serverResponseParser } from '../../../utils/request/parser'
import { postAuthenticatedRequest } from '../request'
import {
  COORDINATION_CONSUMER,
  RESOURCES,
  SearchResult,
  postSearchResultsParser,
} from './types'

export const postSearchResults = async (
  query: string
): Promise<SearchResult[]> => {
  const searchRequest = {
    text: query,
    resource: RESOURCES.GLOBAL,
    consumer: COORDINATION_CONSUMER,
  }
  return postAuthenticatedRequest(
    `ops/v1/search`,
    searchRequest,
    (statusCode: number, data: unknown) =>
      serverResponseParser({ success: true, data }, postSearchResultsParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}
export default {
  postSearchResults,
}
