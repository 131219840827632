import { RemoteData, cata } from 'remote-data-ts'
import { ClientProfile, MerchantProfile } from '../../api/profiles/types'
import { ACTION_TYPES, Action } from './actions'

export type ReduxState = {
  clients: ClientProfile[]
  merchants: MerchantProfile[]
}

export type RemoteDataState = RemoteData<ReduxState, Error>

export const initialReduxState: ReduxState = {
  clients: [],
  merchants: [],
}

export const initialRemoteDataState: RemoteDataState = RemoteData.notAsked()

export const getClientProfileByClientId = (
  state: ReduxState,
  clientId: number
): ClientProfile | undefined => {
  const [result] = state.clients.filter(client => client.clientId === clientId)
  return result
}

export const reduxReducer = (
  state: ReduxState | undefined = initialReduxState,
  action: Action
): ReduxState => {
  if (action.type === ACTION_TYPES.UPDATE_CLIENT_PROFILE_BY_CLIENT_ID) {
    const clients = state.clients.filter(
      ({ clientId }) => clientId === action.data.clientProfile.clientId
    )

    return {
      ...state,
      clients: [...clients, action.data.clientProfile],
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_CLIENT_PROFILE) {
    return {
      ...state,
      clients: state.clients
        .filter(
          client => client.clientId !== action.data.clientProfile.clientId
        )
        .concat(action.data.clientProfile),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_CLIENT_PROFILES) {
    const clientIdList = action.data.clientProfiles
      .reduce(
        (clientIds: number[], clientProfile: ClientProfile) =>
          clientIds.concat(clientProfile.clientId),
        []
      )
      .toString()

    return {
      ...state,
      clients: state.clients
        .filter(client => clientIdList.indexOf(client.clientId.toString()) >= 0)
        .concat(action.data.clientProfiles),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_MERCHANT_PROFILE) {
    return {
      ...state,
      merchants: state.merchants
        .filter(
          merchant =>
            merchant.merchantId !== action.data.merchantProfile.merchantId
        )
        .concat(action.data.merchantProfile),
    }
  }

  if (action.type === ACTION_TYPES.UPDATE_MERCHANT_PROFILES) {
    const merchantIdList = action.data.merchantProfiles
      .reduce(
        (merchantIds: number[], merchantProfile: MerchantProfile) =>
          merchantIds.concat(merchantProfile.merchantId),
        []
      )
      .toString()

    return {
      ...state,
      merchants: state.merchants
        .filter(
          merchant =>
            merchantIdList.indexOf(merchant.merchantId.toString()) >= 0
        )
        .concat(action.data.merchantProfiles),
    }
  }

  return state
}

export const remoteDataReducer = (
  state: RemoteDataState = initialRemoteDataState,
  action: Action
): RemoteDataState => {
  return cata<ReduxState, Error, RemoteDataState>({
    notAsked: () => {
      if (
        action.type === ACTION_TYPES.UPDATE_CLIENT_PROFILE ||
        action.type === ACTION_TYPES.UPDATE_MERCHANT_PROFILE ||
        action.type === ACTION_TYPES.UPDATE_CLIENT_PROFILES ||
        action.type === ACTION_TYPES.UPDATE_MERCHANT_PROFILES
      ) {
        return RemoteData.success(reduxReducer(undefined, action))
      }

      return state
    },
    loading: () => {
      return state
    },
    success: (reduxState: ReduxState) => {
      return RemoteData.success(reduxReducer(reduxState, action))
    },
    failure: (error: Error) => {
      return state
    },
  })(state)
}

export default remoteDataReducer
