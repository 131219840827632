import styled from 'styled-components'

export interface IDateButton {
  isSelected?: boolean
  isSecondary?: boolean
}

export default styled.button<IDateButton>`
  align-items: center;
  background-color: ${props => (props.isSelected ? '#344e63' : 'transparent')};
  border-radius: 100%;
  border: 0px;
  color: ${props =>
    props.isSelected ? '#fff' : props.isSecondary ? '#aaa' : '#344e63'}
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')}
  justify-content: center;
  height: 40px;
  outline: none;
  width: 40px;

  &:focus,
  &:hover {
    background-color: #344e63;
    color: #fff;
    font-weight: bold;
  }
`
