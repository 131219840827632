import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { MATCH_STATUS_TYPE } from '../../../../constants'
import AvailabilityCard from '../../../components/cards/Card'
import _MatchStatusIcon from '../../icons/MatchStatusIcon'
import _AvailabilitiesListCard, {
  IAvailabilitiesListCard,
} from '../AvailabilitiesListCard'

export interface IAvailabilityCard extends IAvailabilitiesListCard {
  className?: string
  headerChildren?: React.ReactNode[] | React.ReactNode | null
  jobMatchStatusType?: MATCH_STATUS_TYPE | null
  selectedIndices?: number[]
  subHeaderChildren?: React.ReactNode[] | React.ReactNode | null
}

export const AvailabilitiesListCard = styled(_AvailabilitiesListCard)`
  margin-top: 25px;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  width: 100%;
`

export const Body = styled.div`
  span {
    align-items: center;
    display: flex;
  }
`

export const MatchStatusIcon = styled(_MatchStatusIcon)`
  margin-left: 10px;
`

export default ({
  className = '',
  headerChildren = null,
  jobMatchStatusType = null,
  onAddAvailability = noop,
  onDeleteAvailability = noop,
  options = [],
  selectedIndices = [],
  subHeaderChildren = null,
}: IAvailabilityCard) => (
  <AvailabilityCard className={className}>
    <Header>
      <h1>{headerChildren}</h1>
      <h4>{subHeaderChildren}</h4>
    </Header>
    <Body>
      <h2>
        Availability: <MatchStatusIcon type={jobMatchStatusType} />
      </h2>
      <AvailabilitiesListCard
        onAddAvailability={onAddAvailability}
        onDeleteAvailability={onDeleteAvailability}
        options={options}
        selectedIndices={selectedIndices}
      />
    </Body>
  </AvailabilityCard>
)
