import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import _PlusIcon from '../../icons/PlusIcon'
import SecondaryButton from '../SecondaryButton'

export interface IAddLabelledButton {
  className?: string
  children?: React.ReactNode[] | React.ReactNode | null
  onClick?: () => void
}

export const AddLabelledButton = styled(SecondaryButton)`
  background-color: rgba(0, 0, 0, 0.02);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 14px;
  width: auto;

  span {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

export const PlusIcon = styled(_PlusIcon)`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-right: 10px;
`

export default ({
  className = '',
  children = null,
  onClick = noop,
}: IAddLabelledButton) => (
  <AddLabelledButton className={className} onClick={onClick}>
    <span>
      <PlusIcon />
      {children}
    </span>
  </AddLabelledButton>
)
