import moment from 'moment'

export const formatDateString = (
  date: Date,
  format: string = 'YYYY-MM-DD'
): string => moment(date).format('MMM DD, YYYY')

export const formatDateIntervalString = (
  startDate: Date,
  endDate: Date,
  dateFormat: string = 'dddd, MMM DD, YYYY',
  timeFormat: string = 'h:mm a'
) =>
  [
    moment(startDate).format(dateFormat),
    'from',
    moment(startDate).format(timeFormat),
    'to',
    moment(endDate).format(timeFormat),
  ].join(' ')

export const formatUserFullName = (
  lastName: string = '',
  firstName: string = '',
  middleName: string = ''
) => [lastName, [firstName, middleName].join(' ')].join(', ')

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const match = ('' + phoneNumberString)
    .replace(/\D/g, '')
    .match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export const formatTo12HourTime = (date: Date): string => {
  return moment(date).format('hh:mm a')
}
