import React from 'react'
import AsyncSelect from 'react-select/async'
import { AsyncProps } from 'react-select/src/Async'
import { AdditionalParams, DropdownProps } from '../Dropdown'

export interface AsyncDropdownProps extends Omit<DropdownProps, 'options'> {
  loadOptions: AsyncProps<AdditionalParams>['loadOptions']
}

export default (props: AsyncDropdownProps) => (
  <AsyncSelect<AdditionalParams> {...props} />
)
