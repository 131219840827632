import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { SELECT_JOB_BOOKING_LABEL } from '../../../../constants'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import ScheduleJobStep1Card from '../../../components/cards/Card'
import _SelectableList, { ISelectableList } from '../../lists/SelectableList'

const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Wrapper = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  height: 115px;
  margin-bottom: 30px;
  width: 360px;
`

const SelectableList = styled(_SelectableList)<ISelectableList>`
  button {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    height: 32px;
    padding: 0px;
  }
`

export interface IScheduleJobStep1 {
  className?: string
  availabilities?: string[]
  onNextStep?: () => void
  onSelectOption?: (optionIndex: number) => void
  selectedIndices?: number[]
}

export default ({
  className = '',
  availabilities = [],
  onSelectOption = noop,
  onNextStep = noop,
  selectedIndices = [],
}: IScheduleJobStep1) => {
  return (
    <ScheduleJobStep1Card className={className}>
      <Column>
        <h2>{SELECT_JOB_BOOKING_LABEL} </h2>
        <Wrapper>
          <SelectableList
            options={availabilities}
            onSelectOption={onSelectOption}
            selectedIndices={selectedIndices}
          />
        </Wrapper>
        <PrimaryButton
          isSelected={!!selectedIndices.length}
          onClick={() => onNextStep()}
        >
          Next
        </PrimaryButton>
      </Column>
    </ScheduleJobStep1Card>
  )
}
