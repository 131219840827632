import { getAuthenticatedRequest, patchAuthenticatedRequest } from '../request'
import {
  ClientAndMerchantProfiles,
  ClientProfile,
  PatchClientByClientId,
  clientAndMerchantProfilesParser,
  clientProfileParser,
  patchClientByClientIdParser,
} from './types'

import { serverResponseParser } from 'utils/request/parser'

export const getClientProfileByClientId = (
  clientId: number
): Promise<ClientProfile> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/profiles/clients/${clientId}`,
    (statusCode: number, data: unknown) => clientProfileParser.check(data),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const getClientAndMerchantProfiles = (): Promise<ClientAndMerchantProfiles> =>
  getAuthenticatedRequest(
    `ops/v2/coordination/profiles`,
    (statusCode: number, data: unknown) =>
      clientAndMerchantProfilesParser.check(data),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )

export const patchClientByClientId = async (
  client: ClientProfile,
  clientId: number
): Promise<PatchClientByClientId> => {
  const clientData = {
    is_vip: client.isVip,
  }

  return patchAuthenticatedRequest(
    `ops/v2/coordination/profiles/clients/${clientId}`,
    clientData,
    (statusCode: number, data: unknown) =>
      serverResponseParser(data, patchClientByClientIdParser),
    (statusCode: number, message: string) => {
      throw new Error(`An error occurred: ${message}`)
    }
  )
}

export default {
  getClientProfileByClientId,
  getClientAndMerchantProfiles,
  patchClientByClientId,
}
