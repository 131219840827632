import * as rt from 'runtypes'

export enum PROFILE_TYPES {
  CLIENT = 'client',
  MERCHANT = 'merchant',
}

export const clientProfileParser = rt.Record({
  type: rt.Literal(PROFILE_TYPES.CLIENT),
  clientId: rt.Number,
  email: rt.String,
  firstName: rt.String,
  lastName: rt.String,
  cellphone: rt.String,
  isVip: rt.Boolean,
})

export const merchantProfileParser = rt.Record({
  type: rt.Literal(PROFILE_TYPES.MERCHANT),
  merchantId: rt.Number,
  email: rt.String,
  name: rt.String,
  primaryPhoneNumber: rt.String.Or(rt.Undefined),
  secondaryPhoneNumber: rt.String.Or(rt.Undefined),
})

export const clientAndMerchantProfilesParser = rt.Record({
  clientProfiles: rt.Array(clientProfileParser),
  merchantProfiles: rt.Array(merchantProfileParser),
})

export const patchClientByClientIdParser = rt.Record({
  profile: clientProfileParser,
})

export type ClientProfile = rt.Static<typeof clientProfileParser>

export type MerchantProfile = rt.Static<typeof merchantProfileParser>

export type ClientAndMerchantProfiles = rt.Static<
  typeof clientAndMerchantProfilesParser
>

export type PatchClientByClientId = rt.Static<
  typeof patchClientByClientIdParser
>

export default {
  clientProfileParser,
  merchantProfileParser,
  clientAndMerchantProfilesParser,
  patchClientByClientIdParser,
}
