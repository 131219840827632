import { cata } from 'remote-data-ts'
import { createSelector } from 'reselect'
import { Job } from '../../api/jobs/types'
import { IState } from '../index'
import { ReduxState } from './index'

export const getJobsSelector = (state: IState): ReduxState =>
  cata({
    loading: () => new Map(),
    notAsked: () => new Map(),
    failure: () => new Map(),
    success: (reduxState: ReduxState) => reduxState,
  })(state.jobs)

export const getJobByJobIdSelector = createSelector(
  getJobsSelector,
  jobs => (jobId: number): Job | undefined => jobs.get(jobId)
)

export default {
  getJobsSelector,
  getJobByJobIdSelector,
}
