import React from 'react'
import Icon from '../Icon'
import { ReactComponent as EmojiComponent } from './assets/neutral-face-emoji.svg'

export const NeutralFaceIcon = Icon

export interface INeutralFaceIcon {
  className?: string
}
export default ({ className = '' }: INeutralFaceIcon) => (
  <NeutralFaceIcon className={className}>
    <EmojiComponent />
  </NeutralFaceIcon>
)
