import { REQUEST_METHOD_TYPE, createRequest } from '../../../utils/request'
import store from '../../redux'
import { getAuthenticationTokenSelector } from '../../redux/session/selectors'

// TODO: add CI check for required build-time environment variables
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://staging.api.setter.com'

export const SSO_BASE_URL =
  process.env.REACT_APP_SSO_BASE_URL || 'https://staging.sso.setter.com'

export interface IAuthenticationHeader {
  Authorization: string
}

export const createRequestUrl = (path: string) => `${API_BASE_URL}/${path}`

export const getAuthenticationHeader = ():
  | IAuthenticationHeader
  | undefined => {
  const authToken = getAuthenticationTokenSelector(store.getState())
  if (!authToken) {
    return
  }

  return {
    Authorization: authToken,
  }
}

export const deleteRequest = <H, D>(
  path: string,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => D,
  onErrorRequestHandler: (statusCode: number, message: string) => D,
  headers?: H
) => {
  return createRequest({
    headers,
    method: REQUEST_METHOD_TYPE.DELETE,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url: createRequestUrl(path),
  })
}

export const getRequest = <H, D>(
  path: string,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => D,
  onErrorRequestHandler: (statusCode: number, message: string) => D,
  headers?: H,
  url?: string
) =>
  createRequest({
    headers,
    method: REQUEST_METHOD_TYPE.GET,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url: url ? url : createRequestUrl(path),
  })

export const patchRequest = <H, D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R,
  headers?: H
) =>
  createRequest({
    data,
    headers,
    method: REQUEST_METHOD_TYPE.PATCH,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url: createRequestUrl(path),
  })

export const postRequest = <H, D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R,
  headers?: H
) =>
  createRequest({
    data,
    headers,
    method: REQUEST_METHOD_TYPE.POST,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url: createRequestUrl(path),
  })

export const putRequest = <H, D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R,
  headers?: H
) =>
  createRequest({
    data,
    headers,
    method: REQUEST_METHOD_TYPE.PUT,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url: createRequestUrl(path),
  })

export const deleteAuthenticatedRequest = <R>(
  path: string,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R
) => {
  return deleteRequest(
    path,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    getAuthenticationHeader()
  )
}

export const getAuthenticatedRequest = <R>(
  path: string,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R
) =>
  getRequest(
    path,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    getAuthenticationHeader()
  )

export const patchAuthenticatedRequest = <D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R
) =>
  patchRequest(
    path,
    data,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    getAuthenticationHeader()
  )

export const postAuthenticatedRequest = <D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R
) =>
  postRequest(
    path,
    data,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    getAuthenticationHeader()
  )

export const putAuthenticatedRequest = <D, R>(
  path: string,
  data: D,
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R,
  onErrorRequestHandler: (statusCode: number, message: string) => R
) =>
  putRequest(
    path,
    data,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    getAuthenticationHeader()
  )

export default {
  API_BASE_URL,
  createRequestUrl,
  deleteAuthenticatedRequest,
  deleteRequest,
  getAuthenticationHeader,
  getAuthenticatedRequest,
  getRequest,
  patchAuthenticatedRequest,
  patchRequest,
  postAuthenticatedRequest,
  postRequest,
  putAuthenticatedRequest,
  putRequest,
}
