import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { ReactComponent as CloseComponent } from './assets/cross.svg'

export const CrossCircleIcon = styled(Icon)`
  background-color: #000000;
  border-radius: 100%;

  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0.65;
`

export interface ICrossCircleIcon {
  className?: string
}
export default ({ className }: ICrossCircleIcon) => (
  <CrossCircleIcon className={className}>
    <CloseComponent />
  </CrossCircleIcon>
)
