import React from 'react'
import styled from 'styled-components'
import SetterLogoCard from '../../../composites/cards/SetterLogoCard'
import Table, { TableRow as _TableRow } from '../../tables/Table'
export interface IView {
  children?: React.ReactNode | null
  headerChildren?: React.ReactNode | null
}

export const View = styled(Table)``

export const TableRow = styled(_TableRow)`
  background-color: #fff;
`
export default ({ headerChildren, children }: IView) => {
  const headerComponents = (
    <TableRow>
      <SetterLogoCard />
      {headerChildren}
    </TableRow>
  )
  return <View headerChildren={headerComponents}>{children}</View>
}
