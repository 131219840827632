import React from 'react'
import { SSO_BASE_URL } from '../../../../lib/api/request'

const ssoAppId = process.env.REACT_APP_SSO_APP_ID || 'staging-coordination'
const ssoProvider = process.env.REACT_APP_SSO_PROVIDER || 'google'
const ssoRedirectURI =
  process.env.REACT_APP_SSO_REDIRECT_URI ||
  'https://staging.coordination.setter.com'

export default () => {
  const ssoURL = `${SSO_BASE_URL}/loginpage?appId=${ssoAppId}&provider=${ssoProvider}&redirect=${encodeURIComponent(
    ssoRedirectURI
  )}`
  window.location.href = ssoURL
  return <div />
}
