import { noop } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import CrossCircleIcon from '../../icons/CrossCircleIcon'

export interface ICrossButton {
  onClick?: () => void
}

export const CrossButton = styled.button`
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  outline: none;
`

export default ({ onClick = noop }: ICrossButton) => (
  <CrossButton onClick={onClick}>
    <CrossCircleIcon />
  </CrossButton>
)
