import { cata } from 'remote-data-ts'
import { createSelector } from 'reselect'
import { ClientProfile, MerchantProfile } from '../../api/profiles/types'
import { IState } from '../index'
import { ReduxState } from './index'

export const getProfilesSelector = (state: IState): ReduxState =>
  cata({
    notAsked: () => ({ clients: [], merchants: [] }),
    loading: () => ({ clients: [], merchants: [] }),
    success: (reduxState: ReduxState) => reduxState,
    failure: () => ({ clients: [], merchants: [] }),
  })(state.profiles)

export const getClientProfilesSelector = createSelector(
  getProfilesSelector,
  ({ clients }): ClientProfile[] => clients
)

export const getMerchantProfilesSelector = createSelector(
  getProfilesSelector,
  ({ merchants }): MerchantProfile[] => merchants
)

export const getClientProfileByClientIdSelector = createSelector(
  getClientProfilesSelector,
  clientProfiles => (clientId: number): ClientProfile | undefined => {
    const [clientProfile] = clientProfiles.filter(
      client => client.clientId === clientId
    )
    return clientProfile
  }
)

export const getMerchantProfileByMerchantIdSelector = createSelector(
  getMerchantProfilesSelector,
  merchants => (merchantId: number): MerchantProfile | undefined => {
    const [merchantProfile] = merchants.filter(
      merchant => merchant.merchantId === merchantId
    )
    return merchantProfile
  }
)
